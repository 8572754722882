/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

export default class SdpParser {
  private readonly _sdp: string;
  private readonly _audioCodec: string;
  private readonly _videoCodec: string;
  private readonly _isAudioTrackEnabled: boolean;
  private readonly _isVideoTrackEnabled: boolean;

  constructor(sdp: string) {
    this._sdp = sdp;

    const sdpSplitByLines = this._sdp.split(/(\r\n|\r|\n)/);
    const sdpSplitByTracks = this._sdp.split('m=');

    this._audioCodec = this.applyCodec('audio', sdpSplitByLines);
    this._videoCodec = this.applyCodec('video', sdpSplitByLines);
    this._isAudioTrackEnabled = this.isTrackByTypeEnabled('audio', sdpSplitByTracks);
    this._isVideoTrackEnabled = this.isTrackByTypeEnabled('video', sdpSplitByTracks);
  }

  get audioCodec(): string {
    return this._audioCodec;
  }

  get videoCodec(): string {
    return this._videoCodec;
  }

  get isAudioTrackEnabled(): boolean {
    return this._isAudioTrackEnabled;
  }

  get isVideoTrackEnabled(): boolean {
    return this._isVideoTrackEnabled;
  }

  private isTrackByTypeEnabled(type: string, sdpSplitByTracks: string[]): boolean {
    const sdpSectionForType = sdpSplitByTracks.find(sdpTrack => sdpTrack.startsWith(type));

    if (!sdpSectionForType) {
      return false;
    }

    return !sdpSectionForType.includes(`a=inactive`);
  }

  private applyCodec(type: string, sdpSplitByLines: string []): string {
    const usedCodecLine = sdpSplitByLines.find(sdpLine => sdpLine.includes(`a=rtpmap:${sdpSplitByLines.find(i => i.includes(`m=${type}`)).split(' ')[3]}`));
    const usedCodec = usedCodecLine.split(' ')[1];
    const codecName = usedCodec.split('/')[0];

    return codecName;
  }
}