/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Subject from '../../rx/Subject';

export default class StateContext {
  isStarting: Subject<boolean>;
  isDisposed: boolean;

  constructor() {
    this.isStarting = new Subject<boolean>(false);
    this.isDisposed = false;
  }
}