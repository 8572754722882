/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../logger/LoggerFactory';
import Strings from '../lang/Strings';
import Subject from '../rx/Subject';
import ReadOnlySubject from '../rx/ReadOnlySubject';
import EdgeAuthParser from '../edgeAuth/EdgeAuthParser';
import {ILogger} from '../logger/LoggerInterface';
import {LoggingLevel, LoggingLevelType} from '../logger/Logger';
import ConsoleAppender from '../logger/ConsoleAppender';
import TelemetryAppender from '../telemetry/TelemetryApender';
import ConfigurationParameterReader from '../dom/ConfigurationParameterReader';
import MetricsConfiguration, {TelemetryLevel, TelemetryLevelType} from '../metrics/MetricsConfiguration';
import MetricsService from '../metrics/MetricsService';
import TelemetryLevelMapping from '../metrics/TelemetricLevelMapping';
import LoggingLevelMapping from '../logger/LoggingLevelMapping';
import BuildFeatures from '../environment/BuildFeatures';
import BrowserDetector from '../dom/BrowserDetector';
import {IConfigurationParameterReader} from '../dom/IConfigurationParamaterReader';
import ApplicationActivityMonitor from '../dom/ApplicationActivityMonitor';
import IPeerConnectionFactory from '../rtc/IPeerConnectionFactory';
import PeerConnectionService from '../rtc/PeerConnectionService';
import TelemetryUrl from './TelemetryUrl';
import Environment from './Environment';
import {HlsPlayerType, ShakaType, WebPlayerType} from './streaming/StreamTypes';
import DiscoveryUri from './discovery/DiscoveryUri';
import MetricsFactory from '../metrics/MetricsFactory';
import TelemetryDefault from '../metrics/TelemetryDefault';

const pageLoadTime = window['__phenixPageLoadTime'] || window['__pageLoadTime'] || Date.now();

interface IInitOptions {
  discoveryUri?: string;
  peerConnectionFactory?: IPeerConnectionFactory;
  telemetryLevel?: TelemetryLevelType;
  loggingLevel?: LoggingLevelType;
  consoleLoggingLevel?: LoggingLevelType;
  automaticallyPlayMediaStream?: boolean;
  automaticallyMuteVideoOnPlayFailure?: boolean;
  webPlayerLoader?: (player: WebPlayerType) => void;
  shakaPlayerLoader?: (player: ShakaType) => void;
  hlsJsLoader?: (player: HlsPlayerType) => void;
}

export default class SDK {
  private static _automaticallyRetryOnFailure = true;
  private static _automaticallyReconnectPeerConnection = true;
  private static _automaticallyPlayMediaStream = true;
  private static _automaticallyMuteVideoOnPlayFailure = true;
  private static _forceGarbageCollectionOnRestart = true;
  private static _skipGarbageCollectionOnMobileDevices = true;
  private static _webPlayerLoader = null;
  private static _shakaPlayerLoader = null;
  private static _hlsJsLoader = null;
  private static _configurationParameterReader: IConfigurationParameterReader = new ConfigurationParameterReader();
  private static _applicationActivityMonitor: ApplicationActivityMonitor;
  private static _environment: Subject<string> = new Subject<string>('');
  private static _telemetryUrl: Subject<string> = new Subject<string>('https://telemetry.phenixrts.com/telemetry');
  private static _maximalNumberOfPeerConnectionReconnectAttempts = 8;
  private static _telemetryLevel: Subject<TelemetryLevel> = new Subject<TelemetryLevel>(TelemetryDefault.defaultTelemetryLevel);
  private static _metricsService: MetricsService;
  private static _metricsConfiguration: MetricsConfiguration;
  private static readonly _sendLocalCandidates: Subject<boolean> = new Subject(BuildFeatures.sendLocalCandidates);
  private static readonly _tenancy: Subject<string> = new Subject<string>('');
  private static readonly _clientSessionId: string = Strings.random(32);
  private static readonly _loadedTimestamp: Date = new Date();
  private static readonly _logger: ILogger = LoggerFactory.getLogger('SDK');
  private static readonly _initialized: Subject<boolean> = new Subject<boolean>(false);
  private static readonly _readOnlyInitialized: ReadOnlySubject<boolean> = new ReadOnlySubject<boolean>(SDK._initialized);
  private static readonly _readOnlyDiscoveryUri: ReadOnlySubject<string> = new ReadOnlySubject<string>(DiscoveryUri.uri);
  private static readonly _readOnlyPeerConnectionFactory: ReadOnlySubject<IPeerConnectionFactory> = new ReadOnlySubject<IPeerConnectionFactory>(PeerConnectionService.peerConnectionFactory);

  static get pageLoadTime(): number {
    return pageLoadTime;
  }

  static get sendLocalCandidates(): Subject<boolean> {
    return this._sendLocalCandidates;
  }

  static get tenancy(): Subject<string> {
    return this._tenancy;
  }

  static get clientSessionId(): string {
    return SDK._clientSessionId;
  }

  static get loadedTimestamp(): Date {
    return SDK._loadedTimestamp;
  }

  static get initialized(): ReadOnlySubject<boolean> {
    return SDK._readOnlyInitialized;
  }

  static get discoveryUri(): ReadOnlySubject<string> {
    return SDK._readOnlyDiscoveryUri;
  }

  static get peerConnectionFactory(): ReadOnlySubject<IPeerConnectionFactory> {
    return SDK._readOnlyPeerConnectionFactory;
  }

  static get automaticRetryOnFailure(): boolean {
    return SDK._automaticallyRetryOnFailure;
  }

  static get automaticallyReconnectPeerConnection(): boolean {
    return SDK._automaticallyReconnectPeerConnection;
  }

  static get automaticallyPlayMediaStream(): boolean {
    return SDK._automaticallyPlayMediaStream;
  }

  static get automaticallyMuteVideoOnPlayFailure(): boolean {
    return SDK._automaticallyMuteVideoOnPlayFailure;
  }

  static get forceGarbageCollectionOnRestart(): boolean {
    return this._forceGarbageCollectionOnRestart;
  }

  static get skipGarbageCollectionOnMobileDevices(): boolean {
    return this._skipGarbageCollectionOnMobileDevices;
  }

  static get webPlayerLoader(): (WebPlayerType) => void {
    return this._webPlayerLoader;
  }

  static get shakaPlayerLoader(): (ShakaType) => void {
    return this._shakaPlayerLoader;
  }

  static get hlsJsLoader(): (HlsPlayerType) => void {
    return this._hlsJsLoader;
  }

  static get metricsService(): MetricsService {
    return SDK._metricsService;
  }

  static get applicationActivityMonitor(): ApplicationActivityMonitor {
    return this._applicationActivityMonitor;
  }

  static get telemetryUrl(): Subject<string> {
    return this._telemetryUrl;
  }

  static get maximalNumberOfPeerConnectionReconnectAttempts(): number {
    return this._maximalNumberOfPeerConnectionReconnectAttempts;
  }

  static set maximalNumberOfPeerConnectionReconnectAttempts(retriesAmount: number) {
    this._maximalNumberOfPeerConnectionReconnectAttempts = retriesAmount;
  }

  static get loggingLevel(): LoggingLevelType {
    return LoggingLevelMapping.convertLoggingLevelToLoggingLevelType(this._logger.threshold.value);
  }

  static get telemetryLevel(): TelemetryLevelType {
    return TelemetryLevelMapping.convertTelemetryLevelToTelemetryLevelType(SDK._telemetryLevel.value);
  }

  static get browserDetector(): BrowserDetector {
    return BrowserDetector;
  }

  static applyTelemetryConfiguration(): void {
    const telemetryConfiguration = LoggerFactory.telemetryConfiguration;

    telemetryConfiguration.sessionId = SDK.clientSessionId;

    const ignoredEnvironment = SDK._environment.subscribe(environment => {
      telemetryConfiguration.environment = environment;
    });
    const ignoredDiscoveryUri = SDK.telemetryUrl.subscribe(value => {
      const telemetryAppender = this._logger.appenders.value.find(appender => appender instanceof TelemetryAppender);

      if (telemetryAppender) {
        telemetryConfiguration.url = value;

        this._logger.appenders.remove(telemetryAppender);
        this._logger.appenders.add(new TelemetryAppender(telemetryConfiguration));
        this._logger.info('Telemetry URL was set to [%s]', telemetryConfiguration.url);
      }
    });
    const ignoredTenancy = SDK._tenancy.subscribe(tenancy => {
      const telemetryAppender = this._logger.appenders.value.find(appender => appender instanceof TelemetryAppender);

      if (telemetryAppender && tenancy) {
        telemetryConfiguration.tenancy = tenancy;

        this._logger.appenders.remove(telemetryAppender);
        this._logger.appenders.add(new TelemetryAppender(telemetryConfiguration));
        this._logger.info('Telemetry tenancy was set to [%s]', telemetryConfiguration.tenancy);
      }
    });
  }

  static applyMetricsConfiguration(): void {
    SDK._metricsService = MetricsFactory.getMetricsService(DiscoveryUri.uri.value);
    SDK._metricsConfiguration = SDK._metricsService.metricsConfiguration;

    SDK._metricsConfiguration.sessionId = SDK.clientSessionId;

    const ignoredEnvironment = SDK._environment.subscribe(environment => {
      SDK._metricsConfiguration.environment = environment;
    });
    const ignoredDiscoveryUri = SDK.telemetryUrl.subscribe(value => {
      SDK._metricsConfiguration.url = value;
    });
    const ignoredTenancy = SDK._tenancy.subscribe(tenancy => {
      SDK._metricsConfiguration.tenancy = tenancy;
    });
    const value = this._configurationParameterReader.getStringValue('phenix-metrics-level');

    if (value) {
      MetricsFactory.setTelemetryLevel(TelemetryLevel[value]);
    }

    const ignoredTelemetryLevel = SDK._telemetryLevel.subscribe(telemetryLevel => {
      MetricsFactory.setTelemetryLevel(telemetryLevel);
    });
  }

  static applyAutomaticallyRetryOnFailureFromParameterConfiguration(): void {
    this._automaticallyRetryOnFailure = this._configurationParameterReader.getBooleanValue('phenix-automatically-retry-on-failure');
    this._logger.info('Automatically retry on failure is set to: [%s]', this._automaticallyRetryOnFailure);

    return;
  }

  static applyAutomaticallyReconnectPeerConnectionFromParameterConfiguration(): void {
    this._automaticallyReconnectPeerConnection = this._configurationParameterReader.getBooleanValue('phenix-automatically-reconnect-peer-connection');
    this._logger.info('Automatically reconnect peer connection is set to: [%s]', this._automaticallyReconnectPeerConnection);

    return;
  }

  static applyForceGarbageCollectionOnRestartFromParameterConfiguration(): void {
    this._forceGarbageCollectionOnRestart = this._configurationParameterReader.getBooleanValue('phenix-force-garbage-collection-on-restart');
    this._logger.info('Force garbage collection on restart is set to: [%s]', this._forceGarbageCollectionOnRestart);

    return;
  }

  static applySkipGarbageCollectionOnMobileDevicesFromParameterConfiguration(): void {
    this._skipGarbageCollectionOnMobileDevices = this._configurationParameterReader.getBooleanValue('phenix-skip-garbage-collection-on-mobile-devices');
    this._logger.info('Skip garbage collection on mobile devices on restart is set to: [%s]', this._skipGarbageCollectionOnMobileDevices);

    return;
  }

  static applyDiscoveryUriDefaultFromParameterConfiguration(): void {
    SDK.discoveryUri.subscribe(value => {
      SDK.telemetryUrl.value = SDK.getTelemetryUrl(value);
      SDK._environment.value = Environment.getEnvironmentFromUrl(value);
    });

    const channelToken = this._configurationParameterReader.getStringValue('phenix-channel-token');

    if (channelToken) {
      const edgeToken = EdgeAuthParser.parseToken(channelToken);

      SDK._tenancy.value = edgeToken.tenancy;
      DiscoveryUri.uri.value = (edgeToken.uri || SDK.discoveryUri.value).toString();

      this._logger.info('Discovery URI set from configuration parameter to [%s]', SDK.discoveryUri.value);

      return;
    }

    const uriValue = this._configurationParameterReader.getStringValue('phenix-uri');

    if (uriValue) {
      DiscoveryUri.uri.value = uriValue;
      this._logger.info('Discovery URI set from "phenix-uri" configuration parameter tag to [%s]', SDK.discoveryUri.value);

      return;
    }

    const baseURIValue = this._configurationParameterReader.getStringValue('phenix-base-uri');

    if (baseURIValue) {
      DiscoveryUri.uri.value = `${baseURIValue}/pcast/endPoints`;
      this._logger.info('Discovery URI set from "phenix-base-uri" configuration parameter tag to [%s]', SDK.discoveryUri.value);

      return;
    }
  }

  static init(options?: IInitOptions): void {
    if (!this._initialized.value) {
      this._applicationActivityMonitor = new ApplicationActivityMonitor();
    }

    if (options) {
      if (options.discoveryUri) {
        DiscoveryUri.uri.value = options.discoveryUri;
      }

      if (options.peerConnectionFactory) {
        PeerConnectionService.peerConnectionFactory.value = options.peerConnectionFactory;
      }

      if (options.telemetryLevel && TelemetryLevel[options.telemetryLevel]) {
        SDK._telemetryLevel.value = TelemetryLevelMapping.convertTelemetryLevelTypeToTelemetryLevel(options.telemetryLevel);
      }

      if (options.loggingLevel && LoggingLevel[options.loggingLevel]) {
        this._logger.threshold.setThreshold(LoggingLevelMapping.convertLoggingLevelTypeToLoggingLevel(options.loggingLevel));
      }

      if (options.consoleLoggingLevel &&
        LoggingLevel[options.consoleLoggingLevel]) {
        const consoleAppender = this._logger.appenders.value.find(appender => appender instanceof ConsoleAppender);

        if (consoleAppender) {
          this._logger.appenders.remove(consoleAppender);
        }

        if (options.consoleLoggingLevel !== 'Off') {
          this._logger.appenders.add(new ConsoleAppender(LoggingLevelMapping.convertLoggingLevelTypeToLoggingLevel(options.consoleLoggingLevel)));
        }
      }

      if (typeof options.automaticallyPlayMediaStream === 'boolean') {
        this._automaticallyPlayMediaStream = options.automaticallyPlayMediaStream;
      }

      if (typeof options.automaticallyMuteVideoOnPlayFailure === 'boolean') {
        this._automaticallyMuteVideoOnPlayFailure = options.automaticallyMuteVideoOnPlayFailure;
      }

      if (options.webPlayerLoader) {
        this._webPlayerLoader = options.webPlayerLoader;
      }

      if (options.shakaPlayerLoader) {
        this._shakaPlayerLoader = options.shakaPlayerLoader;
      }

      if (options.hlsJsLoader) {
        this._hlsJsLoader = options.hlsJsLoader;
      }
    }

    SDK._initialized.value = true;
  }

  static dispose(): void {
    SDK._initialized.value = false;
    this._applicationActivityMonitor.dispose();
    this._applicationActivityMonitor = null;
  }

  static getTelemetryUrl(url: string): string {
    return TelemetryUrl.getTelemetryUrl(url);
  }

  private constructor() {
    throw new Error('SDK is a static class that may not be instantiated');
  }
}

window.addEventListener('unload', () => {
  SDK.dispose();
});

SDK.applyDiscoveryUriDefaultFromParameterConfiguration();
SDK.applyMetricsConfiguration();
SDK.applyTelemetryConfiguration();
SDK.applyAutomaticallyRetryOnFailureFromParameterConfiguration();
SDK.applyAutomaticallyReconnectPeerConnectionFromParameterConfiguration();
SDK.applyForceGarbageCollectionOnRestartFromParameterConfiguration();
SDK.applySkipGarbageCollectionOnMobileDevicesFromParameterConfiguration();
SDK.init();