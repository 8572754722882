import { BaccaratPlayStore, bindBaccaratPlayStoreMobX } from '../../../store/games/Baccarat';
import { SdkGameKey } from '../../ClientRpcSdk';
import {
	TmpClientRpcSdkWithDevice as ClientRpcSdk,
	ITmpClientRpcSdkWithDeviceConfig as IClientRpcSdkConfig,
} from '../../TmpClientRpcSdkWithDevice';
import { IBaccaratRpcSdk } from './types';

/**
 * @returns A new game specific RPC SDK instance.
 */
const newBaccaratSdk = (config: IClientRpcSdkConfig): IBaccaratRpcSdk => {
	const sdkConfig = { ...config, gameKey: SdkGameKey.BACC };
	const sdk = new ClientRpcSdk<BaccaratPlayStore>(sdkConfig);

	const cfg = sdkConfig.stores?.opts?.playStore;
	const service = sdk.services.getServices().gameService;

	const playStore = (cfg?.instance as BaccaratPlayStore) ?? new BaccaratPlayStore(service, cfg?.opts);

	bindBaccaratPlayStoreMobX(playStore);
	sdk.stores.setPlayStore(playStore);

	return sdk;
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { newBaccaratSdk };
