import { getI18nLib, Internationalization, loadI18nLib } from 'common/client-library';

let i18n: Internationalization = getI18nLib();

const loadI18n = async (): Promise<boolean> => {
	const loaded = await loadI18nLib('en', { i18nInstance: i18n, loadCoreLang: true });
	if (loaded) {
		i18n = getI18nLib();
	}
	return loaded;
};

const getI18n = (): Internationalization => i18n;

export { loadI18n, i18n, getI18n };
