/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import TelemetryDefault from './TelemetryDefault';

export enum TelemetryLevel {
  Off = 100,
  Essential = 10,
  All = -1
}

export type TelemetryLevelType = 'Off' | 'Essential' | 'All';

export default class MetricsConfiguration {
  private _url = 'https://telemetry.phenixrts.com/telemetry/metrics';
  private _tenancy: string;
  private _sessionId: string;
  private _environment: string;
  private _threshold: TelemetryLevel = TelemetryDefault.defaultTelemetryLevel;

  get url(): string {
    return this._url;
  }

  set url(url: string) {
    const metricsUrl = new URL(url);

    metricsUrl.pathname = metricsUrl.pathname + '/metrics';

    this._url = metricsUrl.toString();
  }

  get environment(): string {
    return this._environment;
  }

  set environment(environment: string) {
    this._environment = environment;
  }

  get tenancy(): string {
    return this._tenancy;
  }

  set tenancy(tenancy: string) {
    this._tenancy = tenancy;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  set sessionId(sessionId: string) {
    this._sessionId = sessionId;
  }

  get threshold(): TelemetryLevel {
    return this._threshold;
  }

  set threshold(threshold: TelemetryLevel) {
    this._threshold = threshold;
  }
}