/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {ILogger} from '../logger/LoggerInterface';
import LoggerFactory from '../logger/LoggerFactory';
import ExponentialBackoff from '../time/ExponentialBackoff';
import VersionManager from '../sdk/version/VersionManager';
import MetricsConfiguration from './MetricsConfiguration';
import MetricType from './MetricType';
import MetricsType from './MetricsType';

const requestSizeLimit = 1024;
const maxBufferedRecords = 2048;

interface IValue {
  string?: string;
  uint64?: number;
  float?: number;
}

interface IMetricRecord {
  timestamp: string;
  tenancy: string;
  sessionId: string;
  streamId: string;
  metric: string;
  value: IValue;
  previousValue: IValue;
  fullQualifiedName: string;
  environment: string;
  version: string;
  runtime: number;
  resource?: string;
  kind?: string;
}

interface IMetric {
  streamId?: string;
  metricType: MetricsType;
  value?: IValue;
  previousValue?: IValue;
  runtime: number;
  resource?: string;
  kind?: string;
}

export default class MetricsService {
  private readonly _logger: ILogger = LoggerFactory.getLogger('MetricsService');
  private readonly _metricsConfiguration: MetricsConfiguration;
  private readonly _exponentialBackoff: ExponentialBackoff;
  private _metrics: Array<IMetricRecord> = [];
  private _isSending: boolean;
  private _failureCount = 0;
  private _domain = location.hostname;

  constructor(metricsConfiguration: MetricsConfiguration) {
    this._metricsConfiguration = metricsConfiguration;
    this._exponentialBackoff = new ExponentialBackoff();
  }

  get metricsConfiguration(): MetricsConfiguration {
    return this._metricsConfiguration;
  }

  push(metric: IMetric): void {
    const {streamId, value, previousValue, runtime, resource, kind} = metric;
    const metricType = new MetricType(metric.metricType);

    if (this._metricsConfiguration.threshold > metricType.getTelemetryLevel()) {
      return;
    }

    const metricRecord = {
      timestamp: new Date().toISOString(),
      tenancy: this._metricsConfiguration.tenancy,
      sessionId: this._metricsConfiguration.sessionId,
      streamId,
      metric: metricType.getName(),
      value,
      previousValue,
      fullQualifiedName: this._domain,
      environment: this._metricsConfiguration.environment,
      version: VersionManager.sdkVersion,
      runtime,
      resource,
      kind
    };

    this._metrics.push(metricRecord);

    const ignored = this.sendMetricsIfAble();
  }

  private async sendMetrics(metricsMessages: Array<IMetricRecord>): Promise<Response> {
    const formData = new FormData();

    formData.append('jsonBody', JSON.stringify({records: metricsMessages}));

    return await fetch(this._metricsConfiguration.url, {
      method: 'POST',
      body: formData
    });
  }

  private async sendMetricsIfAble(): Promise<Response | void> {
    if (this._metrics.length <= 0 || this._isSending) {
      return;
    }

    this._isSending = true;

    const metricsMessages = this._metrics.slice(0, requestSizeLimit);

    this._metrics = this._metrics.slice(requestSizeLimit);

    return this.sendMetrics(metricsMessages).then(response => {
      if (this.isResponseStatusCodeRetryable(response)) {
        if (this._metrics.length <= maxBufferedRecords) {
          this._failureCount++;

          setTimeout(() => {
            this._metrics = [...metricsMessages, ...this._metrics];

            this._isSending = false;

            const ignored = this.sendMetricsIfAble();
          }, this._exponentialBackoff.getExponentialBackoffIntervalByFailureCount(this._failureCount));

          return;
        }

        this._logger.error('Too many cached metric records [%s], dropping [%s] records', this._metrics.length, metricsMessages.length);
      }

      this._isSending = false;
      this._failureCount = 0;

      const ignored = this.sendMetricsIfAble();

      return response;
    }).catch(() => {
      this._isSending = false;

      const ignored = this.sendMetricsIfAble();
    });
  }

  private isResponseStatusCodeRetryable({status}: Response): boolean {
    return /^5\d{2}$/.test(status.toString());
  }
}