/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Subject from '../../rx/Subject';
import {IStreamTrackTransform} from '../transformation/StreamTrackTransform';
import {IEncodedStreamSink} from '../transformation/EncodedStreamSink';

export type StreamTransformContextOptions = {
  hasInsertableStreams: boolean;
  hasEncodedInsertableStreams: boolean;
  videoStreamTransformCallback: IStreamTrackTransform<VideoFrame> | undefined;
  audioStreamTransformCallback: IStreamTrackTransform<AudioData> | undefined;
  encodedVideoStreamSink: IEncodedStreamSink<RTCEncodedVideoFrame> | undefined;
  encodedAudioStreamSink: IEncodedStreamSink<RTCEncodedAudioFrame> | undefined;
};

export default class StreamTransformContext {
  readonly hasInsertableStreams: Subject<boolean>;
  readonly hasEncodedInsertableStreams: Subject<boolean>;
  readonly videoStreamTransformCallback: IStreamTrackTransform<VideoFrame> | undefined;
  readonly audioStreamTransformCallback: IStreamTrackTransform<AudioData> | undefined;
  readonly encodedVideoStreamSink: IEncodedStreamSink<RTCEncodedVideoFrame> | undefined;
  readonly encodedAudioStreamSink: IEncodedStreamSink<RTCEncodedAudioFrame> | undefined;

  constructor(options: StreamTransformContextOptions) {
    this.hasInsertableStreams = new Subject<boolean>(options.hasInsertableStreams);
    this.hasEncodedInsertableStreams = new Subject<boolean>(options.hasEncodedInsertableStreams);
    this.videoStreamTransformCallback = options.videoStreamTransformCallback;
    this.audioStreamTransformCallback = options.audioStreamTransformCallback;
    this.encodedVideoStreamSink = options.encodedVideoStreamSink;
    this.encodedAudioStreamSink = options.encodedAudioStreamSink;
  }
}