/**********************************************************************************************************************
 * Initialization of the various stores used by the player client (via the SDK).
 *********************************************************************************************************************/
import { getSdk } from '../sdk';
import { bindGameStoreMobX } from './GameStore';
import { GameStore } from './GameStore';
import { IGameSpecificStores } from './types';

// Instance of the game store
let _gameStore: GameStore;

// ---- Setup ---------------------------------------------------------------------------------------------------------

interface ISetupStoresOpts {
	isDebugEnabled?: Maybe<boolean>;
}

/**
 * Setup the stores used by the player client and binds them to MobX.
 *
 * @returns StoreManager instance containing the stores.
 */
const setupStores = (opts?: Maybe<ISetupStoresOpts>) => {
	const sdk = getSdk();

	if (_gameStore) {
		return sdk.stores.getControllerObj();
	}

	const isDebugEnabled = opts?.isDebugEnabled;

	// Client specific game store
	_gameStore = new GameStore();
	bindGameStoreMobX(_gameStore);

	if (isDebugEnabled === true) {
		const sds = window.sds ?? {};
		sds.getStores = (): IGameSpecificStores => getStores();
	}
};

// ---- Utilities -----------------------------------------------------------------------------------------------------

/**
 * @returns Key-value object of all store instances used by the player client.
 */
const getStores = (): IGameSpecificStores => {
	const sdk = getSdk();

	return {
		...sdk.stores.getStores(),
		gameStore: getGameStore(),
	};
};

/**
 * @returns The game store instance.
 */
const getGameStore = (): GameStore => _gameStore;

/**
 * Clears all player client stores instances.
 */
const clearStores = (): void => {
	const sdk = getSdk();

	sdk.actions.store.clearAllStores();
	_gameStore.clear();
};

// ---- Exports -----------------------------------------------------------------------------------------------------

export { clearStores, getStores, getGameStore, setupStores };
