/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

export default class TelemetryUrl {
  static getTelemetryUrl(url: string): string {
    try {
      const baseURL = new URL(url);
      const segments = baseURL.hostname.split('.');

      switch (baseURL.protocol) {
        case 'ws:':
          baseURL.protocol = 'http:';

          break;
        case 'wss:':
          baseURL.protocol = 'https:';

          break;

        default:
          break;
      }

      if (segments.length === 2 ||
          (segments.length === 3 && segments[segments.length - 2].length <= 2 && segments[segments.length - 1].length <= 3)
      ) {
        segments.unshift('telemetry');
      } else if (segments[0].startsWith('stg-') || segments[0].endsWith('-stg') || segments[0].includes('-stg-') || segments[0] === 'stg') {
        segments[0] = 'telemetry-stg';
      } else if (segments[0].startsWith('local') || segments[0].endsWith('-local')) {
        // Leave URL unchanged
      } else {
        segments[0] = 'telemetry';
      }

      baseURL.hostname = segments.join('.');

      return `${baseURL.origin}/telemetry`;
    } catch (e) {
      return url;
    }
  }
}