/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import {defaultDiscoveryUri} from '../defaults';
import Subject from '../../rx/Subject';
import VersionManager from '../version/VersionManager';
import Parameters from '../../environment/Parameters';

export default class DiscoveryUri {
  private static readonly _discoveryUri: Subject<string> = new Subject<string>(defaultDiscoveryUri);

  static get uri(): Subject<string> {
    return DiscoveryUri._discoveryUri;
  }

  static buildDiscoveryUrl(uri: URL): string {
    const url = new URL(uri.toString());
    const sdkVersion = VersionManager.sdkVersion;
    const intentions = Parameters.discoveryParameters.intentions;

    url.search = `?${new URLSearchParams([['version', sdkVersion], ['intentions', intentions], ['_', `${Date.now()}`]]).toString()}`;

    if (url.pathname === '/') {
      url.pathname = '/pcast/endPoints';
    }

    return url.toString();
  }

  private constructor() {
    throw new Error('DiscoveryUri is a static class that may not be instantiated');
  }
}