import { AnnotationsMap, computed, makeObservable } from 'mobx';
import { PlayStoreMobXAnnotations } from '../../../../store/PlayStore';
import { BaccaratPlayStore } from './BaccaratPlayStore';
import { BaccaratPlayStoreMobXPrivateProps } from './types.mobx';

/**
 * MobX annotations for `BaccaratPlayStore`.
 */
const BaccaratPlayStoreMobXAnnotations: AnnotationsMap<BaccaratPlayStore, BaccaratPlayStoreMobXPrivateProps> = {
	// Inherit from base PlayStore
	...PlayStoreMobXAnnotations,

	// ---- Computed -----------------------------------------------------

	// >> Public

	bankerHand: computed,
	bankerWon: computed,
	gameState: computed,
	isTie: computed,
	phase: computed,
	phaseVal: computed,
	playerGameState: computed,
	playerHand: computed,
	playerWon: computed,
	roadsDataKey: computed,
	roadsDataType: computed,

	// ---- Excluded -----------------------------------------------------
	// NOTHING YET
};

/**
 * Makes the specified `BaccaratPlayStore` instance an observable MobX object.
 */
const bindBaccaratPlayStoreMobX = (instance: BaccaratPlayStore): boolean => {
	if (instance.isMobXBound) {
		return false;
	}

	makeObservable<BaccaratPlayStore, BaccaratPlayStoreMobXPrivateProps>(instance, {
		...BaccaratPlayStoreMobXAnnotations,
	});

	instance.isMobXBound = true;

	return true;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { BaccaratPlayStoreMobXAnnotations, bindBaccaratPlayStoreMobX };
