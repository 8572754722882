/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import IDisposable from './IDisposable';

export default class DisposableList {
  private readonly _list: IDisposable[] = [];

  add(disposable: IDisposable): void {
    this._list.push(disposable);
  }

  dispose(): void {
    this._list.forEach(disposable => disposable.dispose());
    this._list.length = 0;
  }

  toString(): string {
    return `DisposableList[disposables=${this._list.length}]`;
  }
}