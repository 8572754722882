import { useEffect } from 'react';
import delay from 'lodash/delay';

const useTimeout = (callback: () => void, delayMs: number) => {
	useEffect(() => {
		const ref = delay(() => callback(), delayMs);
		return () => clearTimeout(ref);
	}, [callback, delayMs]);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { useTimeout };
