import { useEffect } from 'react';
import delay from 'lodash/delay';

/**
 * Hook used to delay the invocation of a callback.
 *
 * Example:
 * 		const [count, setCount] = useState<number>(0);
 * 		useDelay(() => setCount(count+1), 1000);
 *
 * @param waitMs {number} time in ms to wait for.
 * @param onDone {callback} the callback that gets invoked after the wait time.
 */
const useDelay = (onDone: () => void, waitMs: number) => {
	useEffect(() => {
		const timer = delay(onDone, waitMs);
		return () => globalThis.clearTimeout(timer);
	}, [waitMs, onDone]);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { useDelay as default };
export { useDelay };
