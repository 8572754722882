/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import FeatureEnablement from '../environment/FeatureEnablement';

export default class GarbageCollectorManager {
  static forceGarbageCollection(): void {
    if (FeatureEnablement.isForceChromeGarbageCollectionSupported) {
      this.forceChromeGarbageCollection();
    }
  }

  private static forceChromeGarbageCollection(): void {
    if (FeatureEnablement.isQueueMicrotaskSupported) {
      queueMicrotask(() => {
        this.overloadMemoryToInvokeGarbageCollection();
      });
    } else {
      setTimeout(() => {
        this.overloadMemoryToInvokeGarbageCollection();
      });
    }
  }

  private static overloadMemoryToInvokeGarbageCollection(): void {
    let img = document.createElement('img');

    img.src = window.URL.createObjectURL(new Blob([new ArrayBuffer(10e+7)])); // 100Mb or less or more depending on as you wish to force/invoke GC cycle run
    img.onerror = function() {
      window.URL.revokeObjectURL(this.src);

      img = null;
    };
  }

  private constructor() {
    throw new Error('GarbageCollectorManager is a static class that may not be instantiated');
  }
}