/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
interface IToken {
  uri: URL | null;
  capabilities: string[];
}

interface IEdgeToken {
  applicationId: string;
  token: IToken;
}

export default class EdgeToken {
  private readonly _applicationId: string;
  private readonly _token: IToken;

  constructor(edgeToken: IEdgeToken) {
    this._applicationId = edgeToken?.applicationId || '';
    this._token = {
      uri: edgeToken?.token?.uri ? new URL(edgeToken.token.uri) : null,
      capabilities: edgeToken?.token?.capabilities || []
    };
  }

  get uri(): URL | null {
    return this._token.uri;
  }

  get capabilities(): string[] {
    return this._token.capabilities;
  }

  get tenancy(): string {
    return this._applicationId;
  }
}