import { BufAny, bufCreateRegistry } from '../../../../bufbuild';
import {
	BaccaratGameState,
	BaccaratGameStatePhase,
	BaccaratHand,
	BaccaratPlayerState,
	IBaccaratGameStateData,
	IBaccaratHandData,
	IBaccaratPlayerStateData,
} from '../../../../client/rpc/games/Baccarat';
import { IGameService } from '../../../../client/service/types';
import { IToJsOpts } from '../../../../helpers';
import { IPlayStoreOpts, PlayStore } from '../../../PlayStore';

class BaccaratPlayStore extends PlayStore {
	/* #region ---- CONSTRUCTOR -------------------------------------------------------------------------------------- */

	constructor(service?: Maybe<IGameService>, opts?: Maybe<IPlayStoreOpts>) {
		super(service, opts);

		this._gameTypeRegistry = bufCreateRegistry(BaccaratGameState, BaccaratHand, BaccaratPlayerState);
	}

	/* #endregion ---- CONSTRUCTOR ----------------------------------------------------------------------------------- */

	/* #region ---- Public ------------------------------------------------------------------------------------------ */

	/**
	 * BACC: Game specific state data.
	 */
	public get gameState(): Nullable<IBaccaratGameStateData> {
		const raw = this.data?.gameState ?? null;
		if (raw == null) {
			return null;
		}

		return BaccaratGameState.fromBinary(raw.value) as IBaccaratGameStateData;
	}

	/**
	 * BACC: The player game state for the specific game type.
	 */
	public get playerGameState(): IBaccaratPlayerStateData[] {
		const raw = this.data?.playerGameState ?? null;
		if (raw == null) {
			return [];
		}

		const result: IBaccaratPlayerStateData[] = [];

		raw.forEach((item: BufAny, index: number) => {
			try {
				const entry = BaccaratPlayerState.fromBinary(item.value) as IBaccaratPlayerStateData;
				result.push(entry);
			} catch (e) {
				this.warn(`Unable to convert player game state entry ${index}`, 'playerGameState', {
					item,
					error: e,
				});
			}
		});

		return result;
	}

	/**
	 * BACC: The current phase of the game as a string.
	 */
	public override get phase(): string {
		const phaseVal = this.phaseVal;
		const phaseStr = BaccaratGameStatePhase[phaseVal].toLocaleLowerCase();

		return phaseStr as keyof BaccaratGameStatePhase;
	}

	/**
	 * BACC: The current phase of the game as a raw enum value.
	 */
	public get phaseVal(): BaccaratGameStatePhase {
		return this.gameState?.phase ?? BaccaratGameStatePhase.initial;
	}

	/**
	 * BACC: The current player hand.
	 */
	public get playerHand(): Nullable<IBaccaratHandData> {
		const raw = this.gameState?.playerHand ?? null;

		return raw != null ? (raw as IBaccaratHandData) : null;
	}

	/**
	 * BACC: The current banker hand.
	 */
	public get bankerHand(): Nullable<IBaccaratHandData> {
		const raw = this.gameState?.bankerHand ?? null;

		return raw != null ? (raw as IBaccaratHandData) : null;
	}

	/**
	 * BACC: Player won
	 */
	public get playerWon(): boolean {
		return this.gameState?.playerWon ?? false;
	}

	/**
	 * BACC: Banker won
	 */
	public get bankerWon(): boolean {
		return this.gameState?.bankerWon ?? false;
	}

	/**
	 * BACC: Tie
	 */
	public get isTie(): boolean {
		return this.gameState?.tie ?? false;
	}

	/**
	 * BACC: Roads data key.
	 */
	public get roadsDataKey(): string {
		return this.gameState?.roadsDataKey ?? '';
	}

	/**
	 * BACC: Roads data type.
	 */
	public get roadsDataType(): string {
		return this.gameState?.roadsDataType ?? '';
	}

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current pertinent data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown, opts?: Maybe<IToJsOpts>) => this.toJs(val, extended, { ...opts });

		const result: PlainObject = {
			// Everything from `PlayStore`
			...super.toJson(extended),

			phase: this.phase,
			dealerHand: toJs(this.bankerHand),
			playerHand: toJs(this.playerHand),
			playerWon: this.playerWon,
			bankerWon: this.bankerWon,
			isTie: this.isTie,
			roadsDataKey: this.roadsDataKey,
			roadsDataType: this.roadsDataType,
		};

		if (extended) {
			const baseExtended = (result.extended as PlainObject) ?? {};

			result.extended = {
				...baseExtended,
				gameState: toJs(this.gameState),
				playerGameState: toJs(this.playerGameState),
			};
		}

		return result;
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Debug -------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the base class method.
	 *
	 * @returns The label to use when debugging.
	 */
	protected get debugClassLabel(): string {
		return BaccaratPlayStore.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Store.BaccaratPlayStore`;
	}

	/* #endregion ---- Debug ----------------------------------------------------------------------------------------- */
}

// ---- Exports -------------------------------------------------------------------------------------------------------

export { BaccaratPlayStore as default };
export { BaccaratPlayStore };
