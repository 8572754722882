import { useEffect, useState } from 'react';
import debounce from 'lodash/debounce';

interface IWindowLayoutSize {
	width: Optional<number>;
	height: Optional<number>;
}

interface IActualWindowSize {
	width: number;
	height: number;
}

interface IWindowLayoutResult {
	size: IActualWindowSize;
	ratio: number;
	layout: WindowLayout;
}

enum WindowLayout {
	MOBILE = 'mobile',
	TABLET = 'tablet',
	DESKTOP = 'desktop',
}

const useWindowLayout = (opts?: Maybe<{ width: Optional<number>; height: Optional<number> }>): IWindowLayoutResult => {
	// Initialize state with undefined width/height so server and client renders match
	// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
	const [windowSize, setWindowSize] = useState<IWindowLayoutSize>({
		width: opts?.width,
		height: opts?.height,
	});

	useEffect(() => {
		// Handler to call on window resize
		const handleResize = () => {
			// Set window width/height to state
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		// Add event listener
		window.addEventListener('resize', debounce(handleResize, 200));

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const width = windowSize.width ?? 0;
	const height = windowSize.height ?? 0;

	let layout = WindowLayout.DESKTOP;

	const ratio = width > 0 ? height / width : 0;
	if (ratio > 1.5) {
		layout = WindowLayout.MOBILE;
	} else if (ratio > 1.25) {
		layout = WindowLayout.TABLET;
	}

	return {
		size: {
			width,
			height,
		},
		ratio,
		layout,
	};
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { useWindowLayout as default };
export { useWindowLayout, WindowLayout };
export type { IWindowLayoutSize, IWindowLayoutResult };
