import { ConfigAdapterLdx } from 'common/shared';
import 'helpers/window';

enum GameCustomConfigVars {
	ENABLE_CHAT = 'ENABLE_CHAT',
	DOLBY_STREAM_NAME = 'DOLBY_STREAM_NAME',
	DOLBY_ACCOUNT_ID = 'DOLBY_ACCOUNT_ID',
}

class GameConfigAdapter extends ConfigAdapterLdx {
	// Whether or not to show the chat icon in the game UI.
	public get chatEnabled(): boolean {
		return this.getBoolVar(GameCustomConfigVars.ENABLE_CHAT);
	}
	public get dolbyAccountId(): string {
		return this.getStringVar(GameCustomConfigVars.DOLBY_ACCOUNT_ID);
	}
	public get dolbyStreamName(): string {
		return this.getStringVar(GameCustomConfigVars.DOLBY_STREAM_NAME);
	}
}

const _instance = new GameConfigAdapter();
const getConfig = (): GameConfigAdapter => _instance;

// ---- Exports -------------------------------------------------------------------------------------------------------

export { _instance as default };
export { _instance as Config, GameConfigAdapter };
export { getConfig };
