/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import EndPoint from './EndPoint';
import SDK from '../SDK';
import DiscoveryService from './DiscoveryService';

const discoveryCacheInterval = 60000;

interface IPerURIEndpoint { [x: string]: Promise<EndPoint> }

export default class Discovery {
  private static _cache: IPerURIEndpoint = {};

  static async precacheClosestEndPointDiscovery(): Promise<EndPoint> {
    const uri = new URL(SDK.discoveryUri.value);

    return Discovery.discoverClosestEndPointWithCaching(uri);
  }

  static async discoverClosestEndPointWithCaching(uri: URL): Promise<EndPoint> {
    const url = uri.toString();

    if (Discovery._cache[url]) {
      return Discovery._cache[url];
    }

    const discoveryService = new DiscoveryService(uri);
    const cachedValue = Discovery._cache[url] = discoveryService.discoverClosestEndPoint();
    const ignored = Discovery._cache[url].then(() => {
      const ignored = setTimeout(() => {
        if (Discovery._cache[url] === cachedValue) {
          delete Discovery._cache[url];
        }
      }, discoveryCacheInterval);
    }).catch(() => {
      delete Discovery._cache[url];
    });

    return cachedValue;
  }

  static clearCachedClosestEndpoint(uri: URL): void {
    const url = uri.toString();

    if (Discovery._cache[url]) {
      delete Discovery._cache[url];
    }
  }

  private constructor() {
    throw new Error('Discovery is a static class that may not be instantiated');
  }
}

const ignored = Discovery.precacheClosestEndPointDiscovery();