/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import BuildFeatures from '../environment/BuildFeatures';
import {TelemetryLevel} from '../metrics/MetricsConfiguration';

export default class TelemetryDefault {
  static get defaultTelemetryLevel(): TelemetryLevel {
    return TelemetryLevel[BuildFeatures.sendMetrics];
  }
}