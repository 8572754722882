/**********************************************************************************************************************
 * Main game store. Holds game state data that is not tied to RPC data.
 *********************************************************************************************************************/
import { IViewportWindowSize } from 'common/client-library';
import { GameKey, GameName, GameVariant } from 'Constants';
import { IStoreBase } from 'server/lib-rpc';
import { WindowLayout } from 'helpers/hooks';
import { IGameStoreData } from './types';
import { BaccaratGameState_Phases } from '@sandsb2b/areax-client-library-rpc/dist/grpc/games/baccarat_pb';

class GameStore implements IStoreBase {
	/* #region ---- Properties ----------------------------------------------------------------------------------------*/

	/**
	 * Encapsulated data.
	 */
	protected _data: IGameStoreData;

	/**
	 * When the data was last updated.
	 */
	protected _lastUpdatedTs: number = 0;

	/**
	 * Flag indicating if this class instance is currently bound to MobX as an observable.
	 */
	protected _isMobXBound: boolean = false;

	/* #endregion ---- Properties -------------------------------------------------------------------------------------*/

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor() {
		this._data = GameStore.defaultStoreData();
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public --------------------------------------------------------------------------------------------*/

	/**
	 * @returns The underlying data inside this store.
	 */
	public get data(): IGameStoreData {
		return this._data;
	}

	public soundOn: boolean = true;

	public toggleSound(): boolean {
		return (this.soundOn = !this.soundOn);
	}

	/**
	 * Gets/sets whether or not this class instance is currently bound to MobX as an observable.
	 */
	public get isMobXBound(): boolean {
		return this._isMobXBound;
	}
	public set isMobXBound(value: boolean) {
		this._isMobXBound = value;
	}

	/**
	 * @returns The unique game key.
	 */
	public get gameKey(): string {
		return this._data.gameKey;
	}
	public set gameKey(val: string) {
		this._data.gameKey = val;
		this.setUpdatedTs();
	}

	/**
	 * @returns The game's display name/title.
	 */
	public get gameName(): string {
		return this._data.gameName;
	}
	public set gameName(val: string) {
		this._data.gameName = val;
		this.setUpdatedTs();
	}

	/**
	 * @returns All available chips denominations
	 */
	public get availableChipValues(): number[] {
		return this._data.availableChipsValues;
	}
	public set availableChipValues(val: number[]) {
		this._data.availableChipsValues = val;
		this.setUpdatedTs();
	}

	/**
	 * @returns Additional chips to show win
	 */
	public get additionalWinningChips(): number[] {
		return this._data.additionalWinningChips;
	}
	public set additionalWinningChips(val: number[]) {
		this._data.additionalWinningChips = val;
		this.setUpdatedTs();
	}

	/**
	 * @returns Currently selected chip value
	 */
	public get selectedChipValue(): number {
		return this._data.selectedChipValue;
	}
	public set selectedChipValue(val: number) {
		this._data.selectedChipValue = val;
		this.setUpdatedTs();
	}

	/**
	 * @returns The unix timestamp (UTC) for when the data in this store was last updated.
	 */
	public get lastUpdatedTs() {
		return this._lastUpdatedTs;
	}
	protected setUpdatedTs() {
		this._lastUpdatedTs = Date.now();
	}

	/**
	 * Gets/sets whether the game is loaded or not.
	 */
	public get gameLoaded(): boolean {
		return this._data.gameLoaded;
	}
	public set gameLoaded(val: boolean) {
		this._data.gameLoaded = val;
		this.setUpdatedTs();
	}

	/**
	 * TRUE if the video is currently playing
	 */
	public get videoPlaying(): boolean {
		return this._data.videoPlaying;
	}

	public set videoPlaying(val: boolean) {
		this._data.videoPlaying = val;
		this.setUpdatedTs();
	}

	/**
	 * TRUE if the video is currently loaded
	 */
	public get videoLoaded(): boolean {
		return this._data.videoLoaded;
	}

	public set videoLoaded(val: boolean) {
		this._data.videoLoaded = val;
		this.setUpdatedTs();
	}

	/**
	 * The DOM ID of the video element.
	 */
	public get videoElementId(): string {
		return this._data.videoElementId;
	}
	public set videoElementId(val: string) {
		this._data.videoElementId = val;
		this.setUpdatedTs();
	}

	/**
	 * @returns Window layout
	 */
	public get windowLayout(): WindowLayout {
		return this._data.windowLayout;
	}
	public set windowLayout(val: WindowLayout) {
		this._data.windowLayout = val;
		this.setUpdatedTs();
	}

	/**
	 * @returns Window size
	 */
	public get windowSize(): IViewportWindowSize {
		return this._data.windowSize;
	}
	public set windowSize(val: IViewportWindowSize) {
		this._data.windowSize = val;
		this.setUpdatedTs();
	}

	/**
	 * TRUE if the help screen should be displayed.
	 */
	public get showHelp(): boolean {
		return this._data.showHelp;
	}
	public set showHelp(val: boolean) {
		this._data.showHelp = val;
	}

	/**
	 * TRUE if the menu should be displayed.
	 */
	public get showMenu(): boolean {
		return this._data.showMenu;
	}
	public set showMenu(val: boolean) {
		this._data.showMenu = val;
	}

	/**
	 * TRUE if the options screen should be displayed.
	 */
	public get showOptions(): boolean {
		return this._data.showOptions;
	}
	public set showOptions(val: boolean) {
		this._data.showOptions = val;
	}

	/**
	 * TRUE if the help tip dealer screen should be displayed.
	 */
	public get showTipDealer(): boolean {
		return this._data.showTipDealer;
	}
	public set showTipDealer(val: boolean) {
		this._data.showTipDealer = val;
	}

	/**
	 * TRUE if the help game history screen should be displayed.
	 */
	public get showGameHistory(): boolean {
		return this._data.showGameHistory;
	}
	public set showGameHistory(val: boolean) {
		this._data.showGameHistory = val;
	}

	/**
	 * TRUE if the help game history screen should be displayed.
	 */
	public get showDepositMoney(): boolean {
		return this._data.showDepositMoney;
	}
	public set showDepositMoney(val: boolean) {
		this._data.showDepositMoney = val;
	}

	public get appliedGameState() {
		return this._data.appliedGameState;
	}
	public set appliedGameState(gameState: number) {
		this._data.appliedGameState = gameState;
	}

	/**
	 * ACTION
	 * Clear the store.
	 */
	public clear(): void {
		this._data = GameStore.defaultStoreData();
		this._lastUpdatedTs = 0;
	}

	/* #endregion ---- Public -----------------------------------------------------------------------------------------*/

	/* #region ---- Static --------------------------------------------------------------------------------------------*/

	/**
	 * @returns The default game data.
	 */
	public static defaultStoreData = (): IGameStoreData => ({
		gameKey: GameKey,
		gameVariant: GameVariant,
		gameName: GameName,
		availableChipsValues: [1, 5, 10, 25, 50, 100, 500, 1000],
		selectedChipValue: 50,
		additionalWinningChips: [0.01, 0.05, 0.1, 0.25, 5000, 10000, 25000, 50000, 100000, 150000],
		videoPlaying: false,
		videoLoaded: false,
		videoElementId: '',
		gameLoaded: false,
		windowLayout: WindowLayout.MOBILE,
		windowSize: { width: window.innerWidth, height: window.innerHeight },
		showHelp: false,
		showMenu: false,
		showOptions: false,
		showTipDealer: false,
		showGameHistory: false,
		showDepositMoney: false,
		appliedGameState: BaccaratGameState_Phases.initial,
	});

	/* #endregion ---- Static -----------------------------------------------------------------------------------------*/
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { GameStore as default };
export { GameStore };
