/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

import MetricsService from './MetricsService';
import MetricsConfiguration, {TelemetryLevel} from './MetricsConfiguration';
import SDK from '../sdk/SDK';
import TelemetryUrl from '../sdk/TelemetryUrl';
import Environment from '../sdk/Environment';
import TelemetryLevelMapping from './TelemetricLevelMapping';

export default class MetricsFactory {
  private static _metricsServices: {[category: string]: MetricsService} = {};

  static getMetricsService(url: string): MetricsService {
    if (typeof url !== 'string') {
      throw new Error('ur');
    }

    const metricsServices = MetricsFactory._metricsServices[url];

    if (metricsServices) {
      return metricsServices;
    }

    const metricsConfiguration = new MetricsConfiguration();

    metricsConfiguration.sessionId = SDK.clientSessionId;
    metricsConfiguration.tenancy = SDK.tenancy.value;
    metricsConfiguration.url = TelemetryUrl.getTelemetryUrl(url);
    metricsConfiguration.environment = Environment.getEnvironmentFromUrl(url);

    metricsConfiguration.threshold = TelemetryLevelMapping.convertTelemetryLevelTypeToTelemetryLevel(SDK.telemetryLevel);

    return MetricsFactory._metricsServices[url] = new MetricsService(metricsConfiguration);
  }

  static setTelemetryLevel(telemetryLevel: TelemetryLevel): void {
    const metricsServices = Object.values(MetricsFactory._metricsServices);

    if (metricsServices && metricsServices.length) {
      metricsServices.forEach(metricsService => {
        const metricsConfiguration = metricsService.metricsConfiguration;

        metricsConfiguration.threshold = telemetryLevel;
      });
    }
  }

  private constructor() {
    throw new Error('LoggerFactory is a static class that may not be instantiated');
  }
}