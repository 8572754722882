/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import ChannelContext from '../context/ChannelContext';
import Discovery from '../discovery/Discovery';
import Disposable from '../../lang/Disposable';
import DisposableList from '../../lang/DisposableList';
import LoggerFactory from '../../logger/LoggerFactory';
import {ChannelState} from '../channels';
import {ChunkedStreaming} from './ChunkedStreaming';
import {ChunkedStreamingStatistics} from './StreamTypes';
import IDisposable from '../../lang/IDisposable';
import {IStream} from './IStream';
import {ILogger} from '../../logger/LoggerInterface';

export default class ChunkedStream implements IStream {
  private readonly _logger: ILogger = LoggerFactory.getLogger('ChunkedStream');
  private readonly _channelContext: ChannelContext;
  private readonly _monitorDisposables = new DisposableList();
  private readonly _handleStreamFailure: () => Promise<void>;
  private _streamStatistics: ChunkedStreamingStatistics;
  private _monitorFailureCount = 0;

  constructor(channelContext, handleStreamFailure: () => Promise<void>) {
    this._channelContext = channelContext;
    this._handleStreamFailure = handleStreamFailure;
    this._channelContext.disposables.add(this);
  }

  start(uri: URL, token: string): Promise<void | IDisposable> {
    return Discovery.discoverClosestEndPointWithCaching(uri)
      .then(endPoint => {
        this._channelContext.online.value = true;
        this._channelContext.endPoint.value = endPoint;
        this._logger.info('Connecting to [%s]', endPoint.toString());

        return endPoint.subscribe(token, null, this._channelContext.failureCount.value);
      })
      .then(({status, stream, createOfferDescriptionResponse, lag}) => {
        this._channelContext.stream.value = stream;
        this._channelContext.lag.value = lag;

        this._channelContext.applySessionAndStreamPropertiesToVideoElement();

        this._logger.debug(
          '[%s] Subscribe completed [%s] [%j] [%j] [%j] [%j]',
          this._channelContext.streamId,
          status,
          createOfferDescriptionResponse,
        );

        this._channelContext.state.value = this._channelContext.mapSubscribeStatusToChannelStatus(status);

        this._channelContext.applyStatus(status);

        if (status !== 'ok') {
          return;
        }

        const offerSdp = createOfferDescriptionResponse.sessionDescription.sdp;
        const options = {
          originStartTime: Date.now() - lag + this._channelContext.endPoint.value.roundTripTime / 2,
          lag
        };

        return ChunkedStreaming.start(this._channelContext.videoElement.value, this._channelContext.streamId, offerSdp, options)
          .then(player => {
            this.monitor(player);

            return player;
          })
          .catch(e => {
            this._logger.error('ChunkedStreaming failed', e);
            this._channelContext.loading.value = false;
            this._channelContext.playing.value = false;
            this._channelContext.state.value = ChannelState.Stopped;
          });
      });
  }

  private monitor(player: IDisposable): void {
    this._monitorDisposables.dispose();

    const timeout = window.setTimeout(() => {
      const newStreamStatistics = ChunkedStreaming.getStats();

      if (this._streamStatistics &&
        this._streamStatistics.currentTime >= newStreamStatistics.currentTime &&
        this._streamStatistics.dataBuffered >= newStreamStatistics.dataBuffered
      ) {
        this._monitorFailureCount++;

        if (this._monitorFailureCount > 6) {
          this._monitorFailureCount = 0;
          this._channelContext.state.value = ChannelState.ConnectionError;

          this._channelContext.playing.value = false;
          this._channelContext.loading.value = true;
          player.dispose();
          this._monitorDisposables.dispose();

          const ignored = this._handleStreamFailure()
            .catch(e => {
              this._logger.error('Failed handling stream failure', e);
            });

          return;
        }
      } else {
        this._monitorFailureCount = 0;
      }

      if (this._channelContext.videoElement.value.paused && this._channelContext.state.value === ChannelState.Playing) {
        this._channelContext.state.value = ChannelState.Paused;
      }

      if (!this._channelContext.videoElement.value.paused && this._channelContext.state.value !== ChannelState.Playing) {
        this._channelContext.state.value = ChannelState.Playing;
      }

      this._streamStatistics = newStreamStatistics;
      this.monitor(player);
    }, 1000);

    this._monitorDisposables.add(new Disposable(() => {
      clearTimeout(timeout);
    }));
  }

  dispose(): void {
    this._monitorDisposables.dispose();
  }
}