import { DeviceDataStreamRequestProps } from '../../client/stream/types';
import { EventDispatcherBase } from '../../common';
import { IPlayStore, IPlayStoreOpts, PlayStore } from '../../store';
import { ClientRpcSdk } from '../ClientRpcSdk';
import { OmniSdkEvents as Events } from '../OmniClientRpcSdk/constants';
import { StreamKey } from '../streams';
import { IMethodSetActiveDeviceOpts, ITmpClientRpcSdkWithDevice, ITmpClientRpcSdkWithDeviceConfig } from './types.main';

/**
 * Temporary class for clients that havent moved the text client to the OmniTextClient yet.
 */
class TmpClientRpcSdkWithDevice<
		PlayStoreType extends IPlayStore = PlayStore,
		PlayStoreOptsType extends IPlayStoreOpts = IPlayStoreOpts
	>
	extends ClientRpcSdk<PlayStoreType, PlayStoreOptsType>
	implements ITmpClientRpcSdkWithDevice
{
	/* #region ---- Properties --------------------------------------------------------------------------------------- */

	/**
	 * Configuration values for the SDK.
	 */
	protected override _config!: ITmpClientRpcSdkWithDeviceConfig;

	/* #endregion ---- Properties ------------------------------------------------------------------------------------ */

	/* #region ---- CONSTRUCTOR ---------------------------------------------------------------------------------------*/

	constructor(config: ITmpClientRpcSdkWithDeviceConfig) {
		super(config, true);

		this.setConfig(config);
		this.init();
	}

	/* #endregion ---- CONSTRUCTOR ------------------------------------------------------------------------------------*/

	/* #region ---- Public ------------------------------------------------------------------------------------------- */

	/**
	 * Get the active device ID.
	 */
	public get deviceId(): string {
		return this._config.deviceId || '';
	}
	public set deviceId(val: string) {
		this.setDeviceId(val);
	}
	// Setter method
	protected setDeviceId(val: string) {
		if (val === this._config.deviceId) {
			return;
		}

		if (this.isInitialized) {
			this.setActiveDevice(val);
		} else {
			this.warn(`Setting device ID before SDK is initialized is not supported`, 'setDeviceId');
		}
	}

	/* #endregion ---- Public ---------------------------------------------------------------------------------------- */

	/* #region ---- Protected ---------------------------------------------------------------------------------------- */

	/**
	 * Sets/initializes the class config.
	 *
	 * - Overrides the parent class method.
	 */
	protected override setConfig(config: ITmpClientRpcSdkWithDeviceConfig) {
		const { newConfig, origConfig } = this.resolveConfig(config);
		this._config = newConfig;
		this.setOptions(newConfig); // Set the options for the parent debug class
		this.onSetConfig(newConfig, origConfig);
	}

	/**
	 * @returns Config object with overrides.
	 */
	protected override resolveConfig(config: ITmpClientRpcSdkWithDeviceConfig) {
		const origConfig: ITmpClientRpcSdkWithDeviceConfig = {
			...ClientRpcSdk.defaultOptions(),
			...this._config,
		};

		const newConfig: ITmpClientRpcSdkWithDeviceConfig = {
			...origConfig,
			...config,
		};

		return { origConfig, newConfig };
	}

	/**
	 * Starts the device stream.
	 */
	protected setActiveDevice = async (deviceId: string, opts?: Maybe<IMethodSetActiveDeviceOpts>): Promise<boolean> => {
		const debugMethod = 'setActiveDevice';

		const streamManager = this._streams.streamManager;
		const { deviceStore } = this.stores.getStores();

		opts = opts ?? {};

		const currentDeviceId = this.deviceId;
		if (deviceId === currentDeviceId) {
			return false;
		}

		// What to do when we unset the active table
		const onUnsetDevice = () => {
			streamManager.stop(StreamKey.DeviceStream);
			deviceStore.clear();

			this._config.deviceId = deviceId;
		};

		// Unsetting the SDK active device
		if (deviceId === '') {
			onUnsetDevice();
			return true;
		}

		const restartStream = true;

		// Populate the device store (if necessary)
		if (deviceStore.deviceId !== deviceId) {
			await deviceStore.populate(deviceId);

			if (!deviceStore.isPopulated || deviceStore.deviceId !== deviceId) {
				this.warn(
					`Unable to populate device store using device ID '${deviceId}'. Is the device ID valid?`,
					debugMethod
				);
				onUnsetDevice();
				return false;
			}
		}

		this._config.deviceId = deviceId;
		this.trigger(Events.SET_ACTIVE_DEVICE, { origDeviceId: currentDeviceId, newDeviceId: deviceId });

		// Start the device stream (if necessary)
		const streamKey = StreamKey.DeviceStream;
		const startStream = opts.startDeviceStream ?? true;

		if (startStream && streamManager.isEnabled(streamKey)) {
			const isStarted = streamManager.isActive(streamKey);

			const streamProps: DeviceDataStreamRequestProps = {
				...opts?.streamProps,
				deviceId,
			};

			if (!isStarted) {
				this.info(`Attempting to start the device stream for device ID '${deviceId}'`, debugMethod, { streamProps });
				streamManager.start(streamKey, streamProps);
			} else if (restartStream) {
				this.info(`Attempting to re-start the device stream for device ID '${deviceId}'`, debugMethod, { streamProps });
				streamManager.restart(streamKey, streamProps);
			}
		}

		return true;
	};

	/* #endregion ---- Protected ------------------------------------------------------------------------------------- */

	/* #region ---- Debug ------------------------------------------------------------------------------------------- */

	/**
	 * Overrides the parent class method.
	 *
	 * @returns A JSON export of the current data.
	 */
	public override toJson(extended?: Maybe<boolean>): PlainObject {
		extended = extended ?? false;

		const toJs = (val: unknown) => EventDispatcherBase.toJs(val, { extended });

		const result: PlainObject = {
			...super.toJson(extended),
		};

		if (extended) {
			result.extended = {
				...((result.extended ?? {}) as PlainObject),
				config: toJs({ ...this._config }),
			};
		}

		return result;
	}

	/**
	 * Overrides the parent class property.
	 *
	 * @returns The label to use when debugging.
	 */
	protected override get debugClassLabel(): string {
		return TmpClientRpcSdkWithDevice.debugClassLabel();
	}

	/**
	 * STATIC
	 * @returns Label assigned to this class namespace.
	 */
	protected static debugClassLabel(): string {
		return `RpcLib.Sdk.TmpClientRpcSdkWithDevice`;
	}

	/* #endregion ---- Debug ---------------------------------------------------------------------------------------- */
}

// ---- Export --------------------------------------------------------------------------------------------------------

export { TmpClientRpcSdkWithDevice };
