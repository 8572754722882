import { useEffect, useState } from 'react';
import throttle from 'lodash/throttle';

interface IViewportWindowSize {
	width: number;
	height: number;
}

/**
 * Hook that tracks the current viewport dimensions and updates it when the window is resized.
 */
const useViewport = (throttleMs: number = 250, leading?: boolean, trailing?: boolean): IViewportWindowSize => {
	const [windowSize, setWindowSize] = useState<IViewportWindowSize>({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	const resize = throttle(
		() => {
			if (window.innerWidth !== windowSize.width || window.innerHeight !== windowSize.height) {
				setWindowSize({ width: window.innerWidth, height: window.innerHeight });
			}
		},
		throttleMs,
		{ leading, trailing }
	);

	useEffect(() => {
		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, [resize]);

	return windowSize;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { useViewport as default };
export { useViewport };
export type { IViewportWindowSize };
