/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../logger/LoggerFactory';
import {ILogger} from '../logger/LoggerInterface';
import Subject from '../rx/Subject';
import FeatureEnablement from '../environment/FeatureEnablement';
import IPeerConnection from './IPeerConnection';
import IPeerConnectionFactory from './IPeerConnectionFactory';
import VanillaPeerConnectionFactory from './VanillaPeerConnectionFactory';

export interface IPeerConnectionOfferInit {
  audioTransceiver?: RTCRtpTransceiver;
  videoTransceiver?: RTCRtpTransceiver;
  peerConnection: IPeerConnection;
  localOffer: RTCSessionDescriptionInit;
}

export default class PeerConnectionService {
  private static _logger: ILogger = LoggerFactory.getLogger('PeerConnectionService');
  private static readonly _peerConnectionFactory: Subject<IPeerConnectionFactory> = new Subject<IPeerConnectionFactory>(new VanillaPeerConnectionFactory());
  private static _cached: {
    direction?: RTCRtpTransceiverDirection;
    peerConnection: Promise<IPeerConnectionOfferInit>;
  };

  static get peerConnectionFactory(): Subject<IPeerConnectionFactory> {
    return PeerConnectionService._peerConnectionFactory;
  }

  static initiateInitialPrecaching(): void {
    if (FeatureEnablement.isPrecachingEnabled) {
      const ignored = PeerConnectionService.precacheCreatePeerConnectionOffer()
        .catch(e => PeerConnectionService._logger.error('Failed to pre-cache peer connection offer', e));
    }
  }

  static async precacheCreatePeerConnectionOffer(): Promise<{ peerConnection: Promise<IPeerConnectionOfferInit | void>; direction: RTCRtpTransceiverDirection }> {
    return PeerConnectionService._cached = {
      direction: 'recvonly',
      peerConnection: PeerConnectionService.createPeerConnectionOffer()
        .catch(e => {
          PeerConnectionService._cached = null;

          throw e;
        })
    };
  }

  static async createPeerConnectionOffer(direction: RTCRtpTransceiverDirection = 'recvonly', encodedInsertableStreams = false): Promise<IPeerConnectionOfferInit> {
    if (PeerConnectionService._cached && PeerConnectionService._cached.direction === direction && !encodedInsertableStreams) {
      const value = PeerConnectionService._cached.peerConnection;

      PeerConnectionService._cached = null;

      return value;
    }

    const configuration: RTCConfiguration = {encodedInsertableStreams};

    return PeerConnectionService._peerConnectionFactory.value.createPeerConnection(configuration)
      .then(async peerConnection => {
        const supportsAddTransceiver = peerConnection.supportsAddTransceiver;
        let videoTransceiver, audioTransceiver;

        if (supportsAddTransceiver) {
          audioTransceiver = peerConnection.addTransceiver('audio', {direction});
          videoTransceiver = peerConnection.addTransceiver('video', {direction});

          return {
            audioTransceiver,
            videoTransceiver,
            peerConnection: peerConnection,
            localOffer: await peerConnection.createOffer(undefined)
          };
        }

        const options = direction === 'recvonly' ? {
          offerToReceiveAudio: true,
          offerToReceiveVideo: true
        } : {};

        return {
          peerConnection: peerConnection,
          localOffer: await peerConnection.createOffer(options)
        };
      });
  }

  private constructor() {
    throw new Error('PeerConnectionService is a static class that may not be instantiated');
  }
}

PeerConnectionService.initiateInitialPrecaching();