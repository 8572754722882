/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {ILogger} from './LoggerInterface';
import Logger, {LoggingLevel} from './Logger';
import Appenders from './Appenders';
import LoggingThreshold from './LoggingThreshold';
import ConsoleAppender from './ConsoleAppender';
import TelemetryAppender from '../telemetry/TelemetryApender';
import TelemetryConfiguration from '../telemetry/TelemetryConfiguration';
import ConfigurationParameterReader from '../dom/ConfigurationParameterReader';
import {IConfigurationParameterReader} from '../dom/IConfigurationParamaterReader';
import LoggerDefaults from './LoggerDefaults';

export default class LoggerFactory {
  private static _configurationParameterReader: IConfigurationParameterReader = new ConfigurationParameterReader();
  private static _loggers: {[category: string]: ILogger} = {};
  private static _appenders: Appenders = new Appenders();
  private static _threshold: LoggingThreshold = new LoggingThreshold();
  private static _telemetryConfiguration: TelemetryConfiguration = new TelemetryConfiguration();

  static get telemetryConfiguration(): TelemetryConfiguration {
    return LoggerFactory._telemetryConfiguration;
  }

  static getLogger(category: string): ILogger {
    if (typeof category !== 'string') {
      category = 'SDK';
    }

    const logger = LoggerFactory._loggers[category];

    if (logger) {
      return logger;
    }

    return LoggerFactory._loggers[category] = new Logger(category, this._appenders, this._threshold);
  }

  static applyLoggerConfigFromParameterConfiguration(): void {
    LoggerFactory.applyLoggingLevel();
    LoggerFactory.applyAppenderLoggingLevel('console', LoggerFactory.applyConsoleLogger.bind(this));
    LoggerFactory.applyAppenderLoggingLevel('telemetry', LoggerFactory.applyTelemetryLogger.bind(this));
  }

  private static applyLoggingLevel(): void {
    const parameterValue = this._configurationParameterReader.getStringValue('phenix-logging-level');

    if (LoggingLevel[parameterValue]) {
      this._threshold.setThreshold(LoggingLevel[parameterValue]);
    }

    return;
  }

  private static applyAppenderLoggingLevel(name: string, applyAppender: (level: LoggingLevel) => void): void {
    const parameterValue = this._configurationParameterReader.getStringValue(`phenix-${name}-logging-level`);

    if (parameterValue === 'Off') {
      return;
    }

    applyAppender(LoggingLevel[parameterValue]);
  }

  private static applyConsoleLogger(level: LoggingLevel): void {
    this._appenders.add(new ConsoleAppender(level || LoggerDefaults.defaultConsoleLoggingLevel));
  }

  private static applyTelemetryLogger(level: LoggingLevel): void {
    this._telemetryConfiguration.threshold = level || LoggerDefaults.defaultTelemetryLoggingLevel;

    this._appenders.add(new TelemetryAppender(this._telemetryConfiguration));
  }

  private constructor() {
    throw new Error('LoggerFactory is a static class that may not be instantiated');
  }
}

LoggerFactory.applyLoggerConfigFromParameterConfiguration();