/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Subject from '../../rx/Subject';
import {EncodedEdgeToken} from '../../edgeAuth/EncodedEdgeToken';

export default class TokenContext {
  token: Subject<EncodedEdgeToken>;
  tokenExpiring: Subject<boolean>;

  constructor(token: EncodedEdgeToken) {
    this.token = new Subject<EncodedEdgeToken>(token);
    this.tokenExpiring = new Subject<boolean>(false);
  }
}