/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
export interface IStreamTrackTransform<T = AudioData | VideoFrame> {
    (track: MediaStreamTrack, chunk: T, controller: TransformStreamDefaultController<T>): void | PromiseLike<void>;
}

export default class StreamTrackTransform {
  static validateMediaStreamTrackTransform(mode: 'audio' | 'video', item: IStreamTrackTransform) {
    if (typeof item !== 'function') {
      return {
        valid: false,
        validationResult: `Unexpected ${mode} Media Stream Track transform function type. Found [${typeof item}]`
      };
    }

    if (item.length !== 3) {
      return {
        valid: false,
        validationResult: `Unexpected ${mode} Media Stream Track transform function parameter length. Expected 3. Found [${item.length}]`
      };
    }

    return {
      valid: true,
      validationResult: 'ok'
    };
  }
}