/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
declare const __PARAMETERS__: {
  discovery: IDiscoveryParameter;
};

interface IDiscoveryParameter{
  intentions: string;
}

export default class Parameters {
  private static _discoveryParameters: IDiscoveryParameter ;

  static get discoveryParameters(): IDiscoveryParameter {
    return this._discoveryParameters;
  }

  static applyParameters(): void {
    try {
      const parameters = __PARAMETERS__;

      this._discoveryParameters = 'discovery' in parameters ? parameters.discovery : {intentions: ''};
    } catch (e) {
      this._discoveryParameters = {intentions: ''};
    }
  }
}

Parameters.applyParameters();