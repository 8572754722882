/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
declare const __BUILD_VERSION__: string;

export default class VersionManager {
  private static _defaultVersion = new Date().toISOString();

  static get sdkVersion(): string {
    try {
      return __BUILD_VERSION__;
    } catch (e) {
      return this._defaultVersion;
    }
  }
}