import { useEffect } from 'react';

type EventType = string | keyof WindowEventMap;

/**
 * Hook used to attach an event handler to the window context.
 *
 * Example:
 * 		useWindowEvent('beforeunload', doSomeCleanup);
 */
const useWindowEvent = (
	eventType: EventType,
	handler: (this: Window, ev: any) => any,
	opts?: Optional<AddEventListenerOptions>
) => {
	useEffect(() => {
		window.removeEventListener(eventType, handler, opts);
		window.addEventListener(eventType, handler, opts);

		return () => {
			window.removeEventListener(eventType, handler, opts);
		};
	});
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { useWindowEvent as default };
export { useWindowEvent };
