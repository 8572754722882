/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Disposable from '../lang/Disposable';
import Subject from '../rx/Subject';
import ReadOnlySubject from '../rx/ReadOnlySubject';
import DisposableList from '../lang/DisposableList';

const defaultDocumentFocusIntervalTimeout = 3000;

export default class ApplicationActivityMonitor {
  private readonly _disposables: DisposableList = new DisposableList();
  private readonly _readOnlyIsForeground: ReadOnlySubject<boolean>;
  private readonly _isForeground: Subject<boolean>;
  private _timeOfLastTabFocusChange: number = Date.now();
  private _documentFocusInterval: number;

  constructor() {
    this._isForeground = new Subject<boolean>(true);
    this._readOnlyIsForeground = new ReadOnlySubject(this._isForeground);
    this.detectTabFocusChange();
  }

  get isForeground(): ReadOnlySubject<boolean> {
    return this._readOnlyIsForeground;
  }

  getTimeSinceLastChange(): number {
    return Date.now() - this._timeOfLastTabFocusChange;
  }

  dispose(): void {
    this._disposables.dispose();
  }

  private detectTabFocusChange(): void {
    let hidden;
    let visibilityChange;

    if (typeof document !== 'object') {
      return;
    }

    // Due to typescript not having a definition for 'msHidden' or 'webkitHidden' types
    // Required to @ts-ignore to prevent typescript errors
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange';
      // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    } else if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
      hidden = 'hidden';
      visibilityChange = 'visibilitychange';
    }
    /* eslint-enable @typescript-eslint/ban-ts-comment */

    const handleVisibilityChange = (): void => {
      const isForeground = !document[hidden];

      this.setFocusState(isForeground);
    };

    if (typeof document.addEventListener !== 'undefined' && typeof document[hidden] !== 'undefined') {
      document.addEventListener(visibilityChange, handleVisibilityChange, false);

      this._disposables.add(new Disposable(() => {
        document.removeEventListener(visibilityChange, handleVisibilityChange, false);
      }));
    } else {
      this.listenForDocumentFocus.call(this);
    }
  }

  private listenForDocumentFocus(): void {
    this._documentFocusInterval = window.setInterval(() => {
      const isForeground = document.hasFocus();

      this.setFocusState(isForeground);
    }, defaultDocumentFocusIntervalTimeout);

    this._disposables.add(new Disposable(() => {
      if (this._documentFocusInterval) {
        clearInterval(this._documentFocusInterval);
      }

      this._documentFocusInterval = null;
    }));
  }

  private setFocusState(isForeground): void {
    if (this._isForeground === isForeground) {
      return;
    }

    this._isForeground.value = isForeground;
  }
}