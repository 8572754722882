import omit from 'lodash/omit';

// Specify the game key such as 'blackjack', 'roulette', 'baccarat', etc.
const GameKey = 'baccarat';

// Specify the game variant such as 'standard'.
const GameVariant = 'baccarat';

// Specify the game name such as 'Blackjack', 'Roulette', 'Baccarat', etc.
const GameName = 'baccarat';

// Chip denominations are represented in pennies.
const ChipDenoms: number[] = [100, 500, 2500, 10000, 50000, 100000, 500000, 1000000, 2500000, 5000000];

const DefaultOmnibusToken =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYmYiOjE2ODI2Mjk2MTIsInRva2VuIjoiNWZaaDh4TkdEZ1hDQjl6WGlrT0RIdUVGbmFLU2lRU2kiLCJ1c2VyX2lkIjoiNWIyNGY0OGYtNzk5Yi00Y2FhLWJlNTEtNTI1YTA3YjM0ZThmIiwic2VydmVyX25hbWUiOiJ0ZXN0Iiwic2VydmljZV9uYW1lIjoidGVzdCJ9.Y57z4Hu2IPnGffd-P4pIUMgsjEAb_8uedkyolu96A54';

// States used in the game logic.
enum GameState {
	OPEN = 'open',
	ACTIVE = 'active',
	INITIAL = 'initial',
	WAITING_ON_WAGER = 'waiting_on_wager',
	DEALING = 'dealing',
	PLAYER_CHOICE = 'choice_play_period',
	RESOLVED = 'resolved',
	COMPLETED = 'completed',
	FINAL = 'final',
}

// Wagers map - currently known bets.
const Wagers: Dict<string> = {
	ANTE_WAGER: 'wager_ante',
	PLAY_WAGER: 'wager_play',
	SIX_CARD_WAGER: 'wager_six_card',
	PAIR_OR_MORE_WAGER: 'wager_pair_or_more',
};

const SidebetWagers = omit(Wagers, ['ANTE_WAGER']);

enum ResolutionType {
	NOT_SET = '',
	RESOLUTION = 'resolution',
	PAYOUT = 'payout',
	PUSH = 'push',
}

const UserType: Dict<string> = {
	PLAYER: 'player',
	BANKER: 'banker',
};

const UiButtonType: Dict<string> = {
	MUTE: 'mute',
	MENU: 'menu',
	HELP: 'help',
	OPTIONS: 'options',
	TIP_DEALER: 'tip_dealer',
	GAME_HISTORY: 'game_history',
	DEPOSIT_MONEY: 'deposit_money',
};

const WinType: Dict<string> = {
	NONE: '',
	BASIC_WIN: 'basic_win',
	BIG_WIN: 'big_win',
	HUGE_WIN: 'huge_win',
	ULTIMATE_WIN: 'ultimate_win',
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export {
	GameKey,
	GameName,
	GameVariant,
	ChipDenoms,
	GameState,
	Wagers,
	SidebetWagers,
	ResolutionType,
	UserType,
	UiButtonType,
	WinType,
	DefaultOmnibusToken,
};
