/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Disposable from '../../lang/Disposable';
import {IStreamTrackTransform} from '../transformation/StreamTrackTransform';
import {IEncodedStreamSink} from '../transformation/EncodedStreamSink';

export default class InsertableStreams {
  static applyEncodedStreamTransformCallback(stream: MediaStream, receivers: RTCRtpReceiver[], encodedVideoStreamSink: IEncodedStreamSink<RTCEncodedVideoFrame>, encodedAudioStreamSink: IEncodedStreamSink<RTCEncodedAudioFrame>) : void {
    stream.getTracks().forEach(track => {
      const receiver = receivers.filter(receive => receive.track === track)[0];

      switch (track.kind) {
        case 'video': {
          if (encodedVideoStreamSink) {
            const encodedStreamTransformCallback : TransformerTransformCallback<RTCEncodedVideoFrame, RTCEncodedVideoFrame> = chunk => {
              encodedVideoStreamSink(track, chunk);
            };

            const transformer = new TransformStream({transform: encodedStreamTransformCallback});
            const receiverStreams = receiver.createEncodedStreams();
            const source = receiverStreams.readable;
            const sink = receiverStreams.writable;

            source
              .pipeThrough(transformer)
              .pipeTo(sink);
          }

          break;
        }

        case 'audio':
        default: {
          if (encodedAudioStreamSink) {
            const encodedStreamTransformCallback : TransformerTransformCallback<RTCEncodedAudioFrame, RTCEncodedAudioFrame> = chunk => {
              encodedAudioStreamSink(track, chunk);
            };

            const transformer = new TransformStream({transform: encodedStreamTransformCallback});
            const receiverStreams = receiver.createEncodedStreams();
            const source = receiverStreams.readable;
            const sink = receiverStreams.writable;

            source
              .pipeThrough(transformer)
              .pipeTo(sink);
          }

          break;
        }
      }
    });
  }

  static applyInsertableStreamTransformation(stream: MediaStream, videoStreamTransformCallback: IStreamTrackTransform<VideoFrame>, audioStreamTransformCallback: IStreamTrackTransform<AudioData>) : {transformedStream: MediaStream; disposables: Disposable[]} {
    const transformedStream = new MediaStream();
    const disposables: Disposable[] = [];

    stream.getTracks().forEach(track => {
      switch (track.kind) {
        case 'video': {
          if (videoStreamTransformCallback) {
            const insertableStreamTransformCallback: TransformerTransformCallback<VideoFrame, VideoFrame> = (chunk, controller) => {
              videoStreamTransformCallback(track, chunk, controller);
            };

            const videoTrack = track as MediaStreamVideoTrack;
            const transformer = new TransformStream({transform: insertableStreamTransformCallback});
            const processor = new MediaStreamTrackProcessor({track: videoTrack});
            const generator = new MediaStreamTrackGenerator({kind: videoTrack.kind});
            const source = processor.readable;
            const sink = generator.writable;

            source
              .pipeThrough(transformer)
              .pipeTo(sink);

            transformedStream.addTrack(generator);
            disposables.push(
              new Disposable(() => {
                transformedStream.removeTrack(generator);
              }));
          } else {
            transformedStream.addTrack(track);
          }

          break;
        }

        case 'audio':
        default: {
          if (audioStreamTransformCallback) {
            const insertableStreamTransformCallback: TransformerTransformCallback<AudioData, AudioData> = (chunk, controller) => {
              audioStreamTransformCallback(track, chunk, controller);
            };

            const audioTrack = track as MediaStreamAudioTrack;
            const transformer = new TransformStream({transform: insertableStreamTransformCallback});
            const processor = new MediaStreamTrackProcessor({track: audioTrack});
            const generator = new MediaStreamTrackGenerator({kind: audioTrack.kind});
            const source = processor.readable;
            const sink = generator.writable;

            source
              .pipeThrough(transformer)
              .pipeTo(sink);

            transformedStream.addTrack(generator);

            const mediaStream = new MediaStream();
            const audioPumper = new Audio();

            mediaStream.addTrack(track);
            audioPumper.srcObject = mediaStream;
            document.body.appendChild(audioPumper);
            disposables.push(
              new Disposable(() => {
                transformedStream.removeTrack(generator);
                document.body.removeChild(audioPumper);
              }));
          } else {
            transformedStream.addTrack(track);
          }

          break;
        }
      }
    });

    return {
      transformedStream,
      disposables
    };
  }

  constructor() {
    throw new Error('InsertableStreams is a static class that may not be instantiated');
  }
}