import { useEffect, useRef } from 'react';

const useComponentWillUnmount = (cleanupCallback = () => {}) => {
	const callbackRef = useRef(cleanupCallback);

	callbackRef.current = cleanupCallback;

	useEffect(() => {
		return () => callbackRef.current();
	}, []);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { useComponentWillUnmount as default };
export { useComponentWillUnmount };
