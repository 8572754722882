import { MutableRefObject, useEffect, useRef } from 'react';

/**
 * Hook used to get the previous value of a prop or state prop.
 *
 * Example:
 * 		const [count, setCount] = useState<number>(0);
 * 		const prevCount: number = usePrevious<number>(count);
 */
const usePrevious = <T>(value: T): Optional<T> => {
	const ref: MutableRefObject<Optional<T>> = useRef<T>(value);

	useEffect(() => {
		ref.current = value;
	}, [value]);

	return ref.current;
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { usePrevious as default };
export { usePrevious };
