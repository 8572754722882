/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Channel, {ChannelOptions} from './Channel';
import {IEncodedStreamSink} from '../transformation/EncodedStreamSink';
import {IStreamTrackTransform} from '../transformation/StreamTrackTransform';
import SDK from '../SDK';
import {EncodedEdgeToken} from '../../edgeAuth/EncodedEdgeToken';
import {Millisecond} from '../../units/Units';

type CreateChannelOptions = {
  videoElement: HTMLVideoElement;
  token: EncodedEdgeToken;
  targetLag?: Millisecond;
  videoStreamTransformCallback?: IStreamTrackTransform<VideoFrame>;
  audioStreamTransformCallback?: IStreamTrackTransform<AudioData>;
  encodedVideoStreamSink?: IEncodedStreamSink<RTCEncodedVideoFrame>;
  encodedAudioStreamSink?: IEncodedStreamSink<RTCEncodedAudioFrame>;
};

export default class Channels {
  static createChannel(options: CreateChannelOptions): Channel {
    if (!SDK.initialized.value) {
      throw new Error('SDK is not loaded.');
    }

    const channelOptions: ChannelOptions = {
      targetLag: options.targetLag,
      videoStreamTransformCallback: options.videoStreamTransformCallback,
      audioStreamTransformCallback: options.audioStreamTransformCallback,
      encodedVideoStreamSink: options.encodedVideoStreamSink,
      encodedAudioStreamSink: options.encodedAudioStreamSink
    };

    return new Channel(options.videoElement, options.token, channelOptions);
  }

  private constructor() {
    throw new Error('Channels is a static class that may not be instantiated');
  }
}