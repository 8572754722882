import { SdkEvents } from '../ClientRpcSdk/constants';

const OmniSdkEvents: Record<string, string> = {
	...SdkEvents,
	SET_ACTIVE_DEVICE: 'SET_ACTIVE_DEVICE',
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { OmniSdkEvents };
