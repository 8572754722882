/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import LoggerFactory from '../logger/LoggerFactory';
import {ILogger} from '../logger/LoggerInterface';
import EdgeToken from './EdgeToken';
import {EncodedEdgeToken} from './EncodedEdgeToken';

const edgeAuthTokenPrefix = 'DIGEST:';

export default class EdgeAuthParser {
  private static _logger: ILogger = LoggerFactory.getLogger('EdgeAuthParser');

  static parseToken(token: EncodedEdgeToken): EdgeToken {
    if (!this.isEncodedEdgeTokenValid(token)) {
      EdgeAuthParser._logger.error('Token is not valid [%s]', token);

      return new EdgeToken(null);
    }

    try {
      const decodedToken = atob(token.substr(edgeAuthTokenPrefix.length));
      const edgeToken = JSON.parse(decodedToken);

      edgeToken.token = JSON.parse(edgeToken.token);

      return new EdgeToken(edgeToken);
    } catch (e) {
      EdgeAuthParser._logger.error('Cannot parse token value', e);

      return new EdgeToken(null);
    }
  }

  static isEncodedEdgeTokenValid(token: EncodedEdgeToken): boolean {
    return !!token && token.startsWith(edgeAuthTokenPrefix);
  }
}