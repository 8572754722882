/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import Subject from '../../rx/Subject';
import ChannelState from '../channels/ChannelState';
import Dimension from '../../video/Dimension';
import EndPoint, {IStream, SubscribeStatus} from '../discovery/EndPoint';
import VideoTelemetry from '../../video/VideoTelemetry';
import {IRtcMonitorStatistic, IRtcStatistic} from '../../rtc/RtcConnectionMonitor';
import SDK from '../SDK';
import assertUnreachable from '../../lang/assertUnreachable';
import DisposableList from '../../lang/DisposableList';
import {BitsPerSecond, Millisecond} from '../../units/Units';

const defaultTargetLag = 0;

export type ChannelContextOptions = {
  targetLag?: number;
};

export default class ChannelContext {
  channelInitialization: Date;
  disposables: DisposableList;
  channelDisposables: DisposableList;
  rendererDisposables: DisposableList;
  videoElement: Subject<HTMLVideoElement>;
  state: Subject<ChannelState>;
  autoMuted: Subject<boolean>;
  autoPaused: Subject<boolean>;
  authorized: Subject<boolean>;
  online: Subject<boolean>;
  loading: Subject<boolean>;
  playing: Subject<boolean>;
  standby: Subject<boolean>;
  stopped: Subject<boolean>;
  targetLag: Subject<Millisecond>;
  lag: Subject<Millisecond>;
  bitrateLimit: Subject<BitsPerSecond>;
  resolution: Subject<Dimension>;
  failureCount: Subject<number>;
  endPoint: Subject<EndPoint>;
  stream: Subject<IStream>;
  rtcStatistics: Subject<IRtcMonitorStatistic>;
  videoTelemetry: VideoTelemetry;
  rtcAudioStatistic: IRtcStatistic;
  rtcVideoStatistic: IRtcStatistic;
  clearFailureCountTimeout: number;

  constructor(options?: ChannelContextOptions) {
    this.disposables = new DisposableList();
    this.channelDisposables = new DisposableList();
    this.rendererDisposables = new DisposableList();
    this.channelInitialization = new Date();
    this.videoElement = new Subject<HTMLVideoElement>(null);
    this.state = new Subject<ChannelState>(ChannelState.Starting);
    this.autoMuted = new Subject<boolean>(false);
    this.autoPaused = new Subject<boolean>(false);
    this.authorized = new Subject<boolean>(true);
    this.online = new Subject<boolean>(true);
    this.loading = new Subject<boolean>(false);
    this.playing = new Subject<boolean>(false);
    this.standby = new Subject<boolean>(false);
    this.stopped = new Subject<boolean>(false);
    this.targetLag = new Subject<Millisecond>(options?.targetLag ?? defaultTargetLag);
    this.lag = new Subject<Millisecond>(0);
    this.bitrateLimit = new Subject<BitsPerSecond>(0);
    this.resolution = new Subject<Dimension>(Dimension.empty);
    this.failureCount = new Subject<number>(0);
    this.endPoint = new Subject<EndPoint>(null);
    this.stream = new Subject<IStream>(null);
    this.rtcStatistics = new Subject<IRtcMonitorStatistic>(null);
  }

  get streamId(): string {
    const stream = this.stream.value;

    if (!stream) {
      return '-';
    }

    return stream.streamId;
  }

  applyStatus(status: SubscribeStatus): void {
    switch (status) {
      case 'ok':
        break;
      case 'unauthorized':
      case 'geo-restricted':
      case 'geo-blocked':
        this.authorized.value = false;

      // eslint-disable-next-line no-fallthrough
      case 'no-stream':
      case 'not-found':
        this.failureCount.value = 0;
        this.playing.value = false;
        this.standby.value = true;
        this.stopped.value = false;
        this.stream.value = null;

        return;
      default:
        this.failureCount.value++;
        this.playing.value = false;
        this.standby.value = true;
        this.stopped.value = false;
        this.stream.value = null;

        return;
    }
  }

  applySessionAndStreamPropertiesToVideoElement(): void {
    if (this.videoElement.value && this.videoElement.value.dataset) {
      this.videoElement.value.dataset.sessionId = SDK.clientSessionId;
      this.videoElement.value.dataset.streamId = this.streamId;
    }
  }

  mapSubscribeStatusToChannelStatus(status: SubscribeStatus): ChannelState {
    switch (status) {
      case 'ok':
        return ChannelState.Starting;
      case 'no-stream':
      case 'not-found':
        return ChannelState.StandBy;
      case 'geo-restricted':
        return ChannelState.GeoRestricted;
      case 'geo-blocked':
        return ChannelState.GeoBlocked;
      case 'unauthorized':
        return ChannelState.Unauthorized;
      case 'capacity':
      case 'rate-limited':
      case 'timeout':
        return ChannelState.Recovering;
      case 'forbidden-tag':
        return ChannelState.ConfigurationError;
      case 'port-in-range-not-found':
        return ChannelState.TransientConfigurationError;
      case 'failed':
        return ChannelState.Error;
      default:
        assertUnreachable(status);
    }
  }
}