import axios from 'axios';
import { ConfigAdapter } from '@sandsb2b/sds-client-shared/dist/config';
import {
	Logger as DebugLogger,
	ILogger as IDebugLogger,
	newNoOpLogger,
} from '@sandsb2b/sds-client-shared/dist/logging';
import { generateRandomString } from '@sandsb2b/sds-client-shared/dist/string';
import { PhenixEdgeAuthStorageProvider } from './PhenixEdgeAuthStorageProvider';
import { Channel, CreateEdgeAuthTokenResponse, ICreateEdgeAuthTokenReply, PhenixRTSImport } from './types';

const importPhenixRTS = async (): Promise<PhenixRTSImport> => await import('@phenixrts/sdk');

interface IMakeStreamChannelOpts {
	channelId?: string;
	channelAlias?: string;
	streamToken?: string;
	useStorage?: boolean;
	authApiUri?: string;
}

interface IPhenixAdapterOpts {
	// When TRUE then this adapter will be enabled for usage. NULL means use the config value.
	isEnabled?: Maybe<boolean>;
	// When TRUE will enable debug output for this class instance.
	isDebugEnabled?: Maybe<boolean>;
	// When TRUE will use the session storage provider to store the auth token.
	useStorage?: Maybe<boolean>;
	// Dependency injection: Config instance used for getting config props.
	config?: Maybe<ConfigAdapter>;
	// Dependency injection: Storage provider instance used for session storage.
	storage?: Maybe<PhenixEdgeAuthStorageProvider>;
	// Dependency injection: Storage key used when a default storage provider is created by this class.
	storageKey?: Maybe<string>;
	// Authentication URL
	authUrl?: Maybe<string>;
	// Channel Id
	channelId?: Maybe<string>;
	// Channel Alias
	channelAlias?: Maybe<string>;
	// Stream Token
	streamToken?: Maybe<string>;
}

const defaultOptions = (): IPhenixAdapterOpts => ({
	isEnabled: null,
	isDebugEnabled: false,
	useStorage: true,
	config: null,
	storage: null,
	storageKey: null,
	authUrl: null,
	channelId: null,
	channelAlias: null,
	streamToken: null,
});

class PhenixAdapter {
	protected _options: IPhenixAdapterOpts;
	protected _config: ConfigAdapter;
	protected _phenixRTS: Nullable<PhenixRTSImport> = null;
	protected _isInitialized: boolean = false;
	protected _debug: IDebugLogger;
	protected _instanceId: string = '';

	protected _useStorage: boolean = true;
	protected _storageKey: Nullable<string> = null;
	protected _storage: Nullable<PhenixEdgeAuthStorageProvider> = null;

	protected _isEnabled: Nullable<boolean> = null;
	protected _authUrl: Nullable<string> = null;
	protected _channelId: Nullable<string> = null;
	protected _channelAlias: Nullable<string> = null;
	protected _streamToken: Nullable<string> = null;

	/**
	 * CONSTRUCTOR.
	 */
	constructor(opts?: Maybe<IPhenixAdapterOpts>) {
		this._options = opts ?? defaultOptions();
		this._instanceId = generateRandomString();
		this._debug = this.isDebugEnabled ? newDebugLogger() : newNoOpLogger();
		this._config = this._options.config ?? new ConfigAdapter();

		this._useStorage = this._options.useStorage ?? this._useStorage;
		this._storageKey = this._useStorage ? this._options.storageKey ?? null : this._storageKey;
		const storage = this._useStorage
			? this._options.storage ?? new PhenixEdgeAuthStorageProvider({ storageKey: this._storageKey })
			: null;
		this._storage = storage;

		this._isEnabled = this._options.isEnabled ?? this._isEnabled;
		this._authUrl = this._options.authUrl ?? this._authUrl;
		this._channelId = this._options.channelId ?? this._channelId;
		this._channelAlias = this._options.channelAlias ?? this._channelAlias;
		this._streamToken = this._options.streamToken ?? this._streamToken;

		// this.debug.warn('CONSTRUCT', this._instanceId, this);
	}

	/**
	 * Initialize this class instance.
	 */
	public initialize = async () => {
		if (!this.isEnabled) {
			return false;
		}

		if (this._isInitialized) {
			return true;
		}

		this._phenixRTS = await importPhenixRTS();
		this._phenixRTS.SDK.init({ loggingLevel: 'Warn', consoleLoggingLevel: 'Error' });
		this._isInitialized = true;

		this.debug.info('Initialized');

		return true;
	};

	public get isReady(): boolean {
		return this.isEnabled && this._phenixRTS != null && this._isInitialized;
	}

	public get useStorage(): boolean {
		return this._useStorage;
	}

	public get storageKey(): Nullable<string> {
		return this._storageKey;
	}

	/**
	 * Enabled
	 */
	public get isEnabled(): boolean {
		return this._isEnabled ?? this._config.enablePhenixTelemetry;
	}
	public set isEnabled(val: Nullable<boolean>) {
		this._isEnabled = val;
	}

	/**
	 * Authentication URL
	 */
	public get authUrl(): string {
		return this._authUrl ?? this._config.phenixAuthApiURI;
	}
	public set authUrl(val: Nullable<string>) {
		this._authUrl = val;
	}

	/**
	 * Channel ID
	 */
	public get channelId(): string {
		return this._channelId ?? this._config.phenixChannelId;
	}
	public set channelId(val: Nullable<string>) {
		this._channelId = val;
	}

	/**
	 * Channel Alias
	 */
	public get channelAlias(): string {
		return this._channelAlias ?? this._config.phenixChannelAlias;
	}
	public set channelAlias(val: Nullable<string>) {
		this._channelAlias = val;
	}

	/**
	 * Stream Token
	 */
	public get streamToken(): string {
		return this._streamToken ?? this._config.streamToken;
	}
	public set streamToken(val: Nullable<string>) {
		this._streamToken = val;
	}

	/**
	 * TODO
	 */
	protected canUseEdgeAuth(authUrl: Maybe<string>, channelId: Maybe<string>, channelAlias: Maybe<string>): boolean {
		authUrl = authUrl ?? '';
		channelId = channelId ?? '';
		channelAlias = channelAlias ?? '';

		return authUrl !== '' && (channelId !== '' || channelAlias !== '');
	}

	/**
	 * TODO
	 */
	public createDefaultStreamChannel = async (videoElement: HTMLVideoElement) => {
		const token = this.streamToken || '';

		if (token === '') {
			this.debug.warn('No stream token set in config. Will not connect to channel.', 'createDefaultStreamChannel');
			return null;
		}

		return this.createChannel({ token, videoElement });
	};

	/**
	 * TODO
	 */
	public createAuthenticatedStreamChannel = async (videoElement: HTMLVideoElement) => {
		try {
			return this.makeAuthenticatedStreamChannel(videoElement, this.authUrl, {
				channelId: this.channelId,
				channelAlias: this.channelAlias,
				streamToken: this.streamToken,
			});
		} catch (e: unknown) {
			const err = e as Error;
			this.debug.warn(err.message ?? '', 'createAuthenticatedStreamChannel', err);
		}
	};

	/**
	 * TODO
	 */
	public makeAuthenticatedStreamChannel = async (
		videoElement: HTMLVideoElement,
		authUrl: string,
		opts?: Maybe<IMakeStreamChannelOpts>
	) => {
		opts = opts ?? {};

		if (authUrl === '') {
			throw new Error('Authentication URL must be specified');
		}

		const channelId = opts.channelId ?? '';
		const channelAlias = opts.channelAlias ?? '';
		const streamToken = opts.streamToken ?? '';

		if (channelId === '' && channelAlias === '' && streamToken === '') {
			throw new Error('Must specify a Channel ID/Alias OR a stream token');
		}

		let token = streamToken;

		if (this.canUseEdgeAuth(authUrl, channelId, channelAlias)) {
			const useStorage = opts.useStorage ?? null;

			this.debug.info('Authenticating with channel', 'makeAuthenticatedStreamChannel', {
				authUrl,
				channelId,
				channelAlias,
				useStorage,
			});

			token = (await this.getEdgeAuthToken({ authUrl, channelId, channelAlias, useStorage })) || token;
		}

		if (token === '') {
			throw new Error('No stream token resolved. Could not connect to channel.');
		}

		return this.createChannel({ token, videoElement });
	};

	/**
	 * TODO
	 */
	public createChannel = async (channelOpts: {
		token: string;
		videoElement: HTMLVideoElement;
	}): Promise<Nullable<Channel>> => {
		if (!this.isEnabled) {
			return null;
		}

		!this._isInitialized && (await this.initialize());

		const channel = (this.isReady ? this._phenixRTS?.Channels.createChannel(channelOpts) : null) ?? null;
		this.debug.info('Channel created for token:', 'createChannel', { token: channelOpts.token });

		return channel;
	};

	/**
	 * TODO
	 */
	public destroyChannel = (channel: Channel): void => {
		channel.dispose();
		this.debug.info('Channel cleaned-up', 'destroyChannel');
	};

	/**
	 * TODO
	 */
	public subscribeToChannel = async (channel: Channel, listener?: (T: any) => void): Promise<void> => {
		if (!this.isEnabled) {
			return;
		}

		!this._isInitialized && (await this.initialize());

		this.isReady &&
			channel.authorized.subscribe((authorized: boolean) => {
				if (!authorized) {
					this.debug.error('Phenix channel is not authorized. Token may be invalid.', 'subscribeToChannel');
				}

				this.debug.info('Subscribed to channel', 'subscribeToChannel');

				listener && listener(authorized);
			});
	};

	/**
	 * TODO
	 */
	public getEdgeAuthToken = async (props: {
		authUrl?: Maybe<string>;
		channelId?: Maybe<string>;
		channelAlias?: Maybe<string>;
		useStorage?: Maybe<boolean>;
	}): Promise<string> => {
		const authUrl = props.authUrl || this.authUrl;
		const channelAlias = props.channelAlias || this.channelAlias;
		const channelId = props.channelId || this.channelId;
		const useStorage = props.useStorage ?? this.useStorage;

		const data = (useStorage ? this._storage?.data : null) || {};

		let token = data.token ?? '';
		let expirationTs = data.expirationTs ?? 0;

		const isNewTokenNeeded = token.length === 0 || (expirationTs > 0 && expirationTs <= Date.now());

		if (isNewTokenNeeded) {
			const response = await this.fetchEdgeAuthToken({ authUrl, channelAlias, channelId });

			if (!response.success) {
				this.debug.error('Error getting auth token:', 'getEdgeAuthToken', { error: response.error ?? 'Unknown error' });
				return '';
			}

			token = response.token ?? '';
			expirationTs = response.expirationTs ?? 0;
			useStorage && this._storage && (this._storage.data = { token, expirationTs });
		}

		return token;
	};

	/**
	 * TODO
	 */
	protected fetchEdgeAuthToken = async (props: {
		authUrl?: Maybe<string>;
		channelId?: Maybe<string>;
		channelAlias?: Maybe<string>;
	}): Promise<ICreateEdgeAuthTokenReply> => {
		const { channelId = '', channelAlias = '' } = props;

		if (channelId === '' && channelAlias === '') {
			return { success: false, error: 'Either the Channel ID or Channel Alias must be specified' };
		}

		const authUrl = props.authUrl || this.authUrl;

		const url = `${authUrl}/create-token`;
		const params = { channelId, channelAlias };

		const defaultData = {
			success: false,
			...{ channelId: channelId || undefined, channelAlias: channelAlias || undefined },
		};

		try {
			const response = await axios.post<any, CreateEdgeAuthTokenResponse>(url, params, {
				headers: { 'content-type': 'application/json; charset=UTF-8' },
			});

			return { ...defaultData, ...response.data };
		} catch (e) {
			const err = e as Error;
			return { ...defaultData, error: `Unable to fetch edge token: ${err.message}` };
		}
	};

	protected get isDebugEnabled(): boolean {
		return this._options.isDebugEnabled ?? false;
	}

	protected set isDebugEnabled(isEnabled: boolean) {
		if (isEnabled === this._options.isDebugEnabled) {
			return;
		}

		this._options.isDebugEnabled = isEnabled;
		this._debug = isEnabled ? newDebugLogger() : newNoOpLogger();
	}

	protected get debug(): IDebugLogger {
		return this._debug;
	}
}

const newDebugLogger = (): IDebugLogger => new DebugLogger('PhenixAdapter');

// ---- Export --------------------------------------------------------------------------------------------------------

export { PhenixAdapter as default };
export { PhenixAdapter };
export type { Channel };
