// @generated by protoc-gen-es v1.3.0 with parameter "target=js+dts"
// @generated from file baccarat.proto (package com.sands.ldx.games, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Card } from "./games_common_pb.js";

/**
 * @generated from message com.sands.ldx.games.BaccaratGameState
 */
export const BaccaratGameState = proto3.makeMessageType(
  "com.sands.ldx.games.BaccaratGameState",
  () => [
    { no: 1, name: "phase", kind: "enum", T: proto3.getEnumType(BaccaratGameState_Phases) },
    { no: 2, name: "player_hand", kind: "message", T: BaccaratHand },
    { no: 3, name: "banker_hand", kind: "message", T: BaccaratHand },
    { no: 4, name: "player_won", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "banker_won", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "tie", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "roads_data_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "roads_data_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from enum com.sands.ldx.games.BaccaratGameState.Phases
 */
export const BaccaratGameState_Phases = proto3.makeEnum(
  "com.sands.ldx.games.BaccaratGameState.Phases",
  [
    {no: 0, name: "initial"},
    {no: 1, name: "dealing"},
    {no: 2, name: "result"},
  ],
);

/**
 * @generated from message com.sands.ldx.games.BaccaratPlayerState
 */
export const BaccaratPlayerState = proto3.makeMessageType(
  "com.sands.ldx.games.BaccaratPlayerState",
  () => [
    { no: 1, name: "phase", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message com.sands.ldx.games.BaccaratHand
 */
export const BaccaratHand = proto3.makeMessageType(
  "com.sands.ldx.games.BaccaratHand",
  () => [
    { no: 1, name: "cards", kind: "message", T: Card, repeated: true },
    { no: 2, name: "scores", kind: "scalar", T: 4 /* ScalarType.UINT64 */, repeated: true },
    { no: 3, name: "score", kind: "scalar", T: 4 /* ScalarType.UINT64 */ },
    { no: 4, name: "natural", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "pair", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

