import { useEffect } from 'react';

const useInterval = (callback: () => void, delayMs: number) => {
	useEffect(() => {
		const ref = globalThis.setInterval(callback, delayMs);
		return () => globalThis.clearInterval(ref);
	}, [callback, delayMs]);
};

// ---- Exports -------------------------------------------------------------------------------------------------------

export { useInterval };
