import debounce from 'lodash/debounce';

interface IPhenixEdgeAuthData {
	token?: Nullable<string>;
	expirationTs?: Nullable<number>;
}

const DEFAULT_STORAGE_KEY = 'areax.phenix-edge';

interface IPhenixEdgeAuthStorageProviderOpts {
	storageKey?: Maybe<string>;
}

class PhenixEdgeAuthStorageProvider {
	protected _storageKey = DEFAULT_STORAGE_KEY;
	protected _cachedData: Nullable<IPhenixEdgeAuthData> = null;

	constructor(opts?: Maybe<IPhenixEdgeAuthStorageProviderOpts>) {
		this._storageKey = opts?.storageKey || this._storageKey;
		this.updateCache(this.storedData);
	}

	public get storageKey(): string {
		return this._storageKey;
	}

	public get data(): Nullable<IPhenixEdgeAuthData> {
		return this._cachedData;
	}

	public set data(value: Nullable<IPhenixEdgeAuthData>) {
		this.updateCache(value, true);
	}

	public get token(): Nullable<string> {
		return this.data?.token ?? null;
	}

	public set token(value: Nullable<string>) {
		this.data = { ...(this.data || {}), token: value };
	}

	public get expirationTs(): Nullable<number> {
		return this.data?.expirationTs ?? null;
	}

	public set expirationTs(value: Nullable<number>) {
		this.data = { ...(this.data || {}), expirationTs: value };
	}

	private get storedData(): Nullable<IPhenixEdgeAuthData> {
		const value = this.storage.getItem(this._storageKey) || '';
		return (value ? (JSON.parse(value) as IPhenixEdgeAuthData) : null) || null;
	}

	private set storedData(value: Nullable<IPhenixEdgeAuthData>) {
		if (value == null) {
			this.storage.removeItem(this._storageKey);
		}

		this.storage.setItem(this._storageKey, JSON.stringify(value));
	}

	private get storage(): Storage {
		return globalThis.sessionStorage;
	}

	private updateCache = (
		data: Nullable<IPhenixEdgeAuthData>,
		saveToStorage?: boolean
	): Nullable<IPhenixEdgeAuthData> => {
		this._cachedData = data;
		saveToStorage && this.syncToStorage();

		return this._cachedData;
	};

	syncToStorage = debounce(() => (this.storedData = this._cachedData), 1000, { trailing: true, leading: true });
}

// ---- Export --------------------------------------------------------------------------------------------------------

const instance = new PhenixEdgeAuthStorageProvider();

export { instance as default };
export { instance as StorageProvider, PhenixEdgeAuthStorageProvider };
