/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */

const defaultBackoffIntervalInMillisecond = 2000;
const defaultMinBackoffIntervalInMillisecond = 1;
const defaultMaxBackoffIntervalInMillisecond = 300000;

export default class ExponentialBackoff {
  private readonly _backoffIntervalInMillisecond: number;
  private readonly _minBackoffIntervalInMillisecond: number;
  private readonly _maxBackoffIntervalInMillisecond: number;

  constructor(
    backoffIntervalInMillisecond: number = defaultBackoffIntervalInMillisecond,
    minBackoffIntervalInMillisecond: number = defaultMinBackoffIntervalInMillisecond,
    maxBackoffIntervalInMillisecond: number = defaultMaxBackoffIntervalInMillisecond
  ) {
    this._backoffIntervalInMillisecond = backoffIntervalInMillisecond;
    this._minBackoffIntervalInMillisecond = minBackoffIntervalInMillisecond;
    this._maxBackoffIntervalInMillisecond = maxBackoffIntervalInMillisecond;
  }

  getExponentialBackoffIntervalByFailureCount(failureCount: number): number {
    const backoffIntervalInSeconds = this._backoffIntervalInMillisecond / 1000;
    const randomization = 0.5 + (0.5 * Math.random()); // [0.5, 1)
    const exponentialBackoff = Math.pow(backoffIntervalInSeconds, failureCount - 2); //  [1/4 1/2 1 2 4 8 ....]
    const exponentialBackoffWithRandomization = Math.floor(exponentialBackoff) * randomization;
    const exponentialBackoffWithRandomizationInMillisecond = Math.ceil(Math.max(exponentialBackoffWithRandomization * 1000, this._minBackoffIntervalInMillisecond));

    return Math.min(exponentialBackoffWithRandomizationInMillisecond, this._maxBackoffIntervalInMillisecond);
  }
}