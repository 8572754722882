import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

/**
 * React hook to provide easy access to values & functions used for menu routing.
 */
const useRouting = () => {
	const history = useHistory();
	const location = useLocation();
	const route = useRouteMatch();

	const routeParams: AnyObject = route.params || {};

	return {
		history,
		location,
		route,
		routeParams,
	};
};

// ---- Export --------------------------------------------------------------------------------------------------------

export { useRouting as default };
export { useRouting };
