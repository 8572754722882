/**
 * Copyright 2023 Phenix Real Time Solutions, Inc. Confidential and Proprietary. All Rights Reserved.
 */
import {IStream} from './IStream';
import EdgeAuthParser from '../../edgeAuth/EdgeAuthParser';
import ChunkedStream from './ChunkedStream';
import RealTimeStream from './RealTimeStream';
import {ILogger} from '../../logger/LoggerInterface';
import LoggerFactory from '../../logger/LoggerFactory';

export default class StreamFactory {
  private static _logger: ILogger = LoggerFactory.getLogger('Streaming');

  static create(token: string, channelContext, peerConnectionContext, streamTransformContext, handleStreamFailure: () => Promise<void>): IStream {
    const tokenCapabilities = EdgeAuthParser.parseToken(token).capabilities;

    if (tokenCapabilities.includes('streaming') || tokenCapabilities.includes('on-demand')) {
      return new ChunkedStream(channelContext, handleStreamFailure);
    }

    return new RealTimeStream(channelContext, peerConnectionContext, streamTransformContext, handleStreamFailure);
  }

  constructor() {
    throw new Error('StreamFactory is a static class that may not be instantiated');
  }
}